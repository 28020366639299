import React from 'react'
import PropTypes from 'prop-types'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import _ from 'lodash'
import {
  setSearch,
  clearSearch,
  setJumpToOriginalPageNumber,
  jumpToPageOriginalPdf,
  showJumpToOriginalPageNumberError,
  clearAllFilters,
} from '../reader/DocumentList/DocumentListActions'
import DocumentsCommentsButton from './DocumentsCommentsButton'
import HeaderFilterMessage from './HeaderFilterMessage'
import SearchBar from '../shared/SearchBar'

const ENTER_KEY = 'Enter'
const PAGE_OFF_SET_ONE = 1

class DocumentListHeader extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      numOfPages: 0,
    }
  }

  componentDidMount() {
    const documentsArray = _.values(this.props.documents)
    const numOfPages = documentsArray.length
      ? _.orderBy(documentsArray, ['endPageNum'], ['desc'])[0].endPageNum
      : 0

    this.setState({
      numOfPages,
    })
  }

  onChangeJumpPage = value => {
    this.props.setJumpToOriginalPageNumber(value)
  }

  handleKeyPress = event => {
    if (event.key === ENTER_KEY) {
      const pageNumber = event.target.value
      const doc = _.find(
        this.props.documents,
        doc => pageNumber >= doc.startPageNum && pageNumber <= doc.endPageNum,
      )

      if (!doc) {
        this.props.showJumpToOriginalPageNumberError()
      } else {
        this.props.showPdf(doc.id)()
        this.props.jumpToPageOriginalPdf(
          pageNumber - doc.startPageNum + PAGE_OFF_SET_ONE,
          doc.id,
        )
      }
    }
  }

  render() {
    const props = this.props

    return (
      <div>
        <div className="document-list-header">
          <div className="search-bar-and-doc-count cf-search-ahead-parent">
            <SearchBar
              id="searchBar"
              onChange={props.setSearch}
              onClearSearch={props.clearSearch}
              isSearchAhead
              placeholder="Type to search..."
              value={props.docFilterCriteria.searchQuery}
              size="small"
              analyticsCategory="Claims Folder"
            />
            <SearchBar
              name="jumpToPageNumberOriginalPdf"
              label={false}
              isSearchAhead
              hideClearSearchButton
              placeholder="Jump to page..."
              onChange={this.onChangeJumpPage}
              value={this.props.jumpToPageNumberOriginalPdf}
              onKeyPress={this.handleKeyPress}
              maxLength={7}
              size="small"
              validationError={
                this.props.errors.jumpToPageNumberOriginalPdf.message
                  ? this.props.errors.jumpToPageNumberOriginalPdf.message
                  : null
              }
            />
            <div className="num-of-items">
              {props.numberOfDocuments} Documents
            </div>
            <div className="num-of-items">
              {this.state.numOfPages} Total Pages
            </div>
          </div>
          <DocumentsCommentsButton />
        </div>
        <HeaderFilterMessage docFilterCriteria={props.docFilterCriteria} />
      </div>
    )
  }
}

DocumentListHeader.propTypes = {
  appealId: PropTypes.string,
  clearAllFilters: PropTypes.func,
  clearSearch: PropTypes.any,
  docFilterCriteria: PropTypes.any,
  documents: PropTypes.object,
  noDocuments: PropTypes.bool,
  numberOfDocuments: PropTypes.number.isRequired,
  numberOfPages: PropTypes.number,
  setSearch: PropTypes.func.isRequired,
  jumpToPageNumberOriginalPdf: PropTypes.any,
  setJumpToOriginalPageNumber: PropTypes.any,
  showJumpToOriginalPageNumberError: PropTypes.any,
  showPdf: PropTypes.any,
  jumpToPageOriginalPdf: PropTypes.any,
  errors: PropTypes.any,
}

const mapStateToProps = state => ({
  numberOfDocuments: state.documentList.filteredDocIds
    ? state.documentList.filteredDocIds.length
    : _.size(state.documents),
  documents: state.documents,
  docFilterCriteria: state.documentList.docFilterCriteria,
  appealId: state.pdfViewer.loadedAppealId,
  ..._.pick(state.documentList, 'jumpToPageNumberOriginalPdf', 'errors'),
})

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      setSearch,
      clearSearch,
      clearAllFilters,
      setJumpToOriginalPageNumber,
      jumpToPageOriginalPdf,
      showJumpToOriginalPageNumberError,
    },
    dispatch,
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(DocumentListHeader)
