import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import _ from 'lodash'
import Button from '../shared/Button'

import StatusMessage from '../shared/StatusMessage'
import {clearSearch} from '../reader/DocumentList/DocumentListActions'

export class NoSearchResults extends PureComponent {
  render() {
    return (
      <div className="section--no-search-results">
        <StatusMessage title="Search results not found">
          Sorry! We couldn&apos;t find anything for &quot;
          {this.props.searchQuery.trim()}
          .&quot;
          <br />
          Please search again or{' '}
          <Button classNames={['cf-btn-link']} onClick={this.props.clearSearch}>
            go back to the document list.
          </Button>
        </StatusMessage>
      </div>
    )
  }
}

NoSearchResults.propTypes = {
  clearSearch: PropTypes.func.isRequired,
  searchQuery: PropTypes.string,
}

const mapStateToProps = state =>
  _.pick(state.documentList.docFilterCriteria, 'searchQuery')

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      clearSearch,
    },
    dispatch,
  )

export default connect(mapStateToProps, mapDispatchToProps)(NoSearchResults)
