import React from 'react'
import PropTypes from 'prop-types'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'
import {version} from 'pdfjs-dist'
import jwt_decode from 'jwt-decode'

import {getQueryParams} from '../../util/QueryParamsUtil'
import AppFrame from '../shared/AppFrame'
import PageRoute from '../shared/PageRoute'
import PdfViewer from './PdfViewer'
import PdfListView from './PdfListView'
import ReaderLoadingScreen from './ReaderLoadingScreen'
import CaseSelect from './CaseSelect'
import CaseSelectLoadingScreen from './CaseSelectLoadingScreen'
import {onScrollToComment} from '../reader/Pdf/PdfActions'
import {setCategoryFilter} from '../reader/DocumentList/DocumentListActions'
import {stopPlacingAnnotation} from '../reader/AnnotationLayer/AnnotationActions'
import {receiveCurrentUser} from '../reader/Auth/AuthActions'

import {documentCategories} from './constants'
import _ from 'lodash'
import NavigationBar from '../shared/NavigationBar'
import Footer from '../shared/Footer'
import {LOGO_COLORS} from '../../constants/AppConstants'

const fireSingleDocumentModeEvent = _.memoize(() => {
  // window.analyticsEvent(CATEGORIES.VIEW_DOCUMENT_PAGE, 'single-document-mode');
})

export class DecisionReviewer extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isCommentLabelSelected: false,
    }

    this.routedPdfListView.displayName = 'RoutedPdfListView'
    this.routedPdfViewer.displayName = 'RoutedPdfViewer'
    this.routedCaseSelect.displayName = 'RoutedCaseSelect'
  }

  showPdf = (history, appealId) => docId => () => {
    if (!this.props.storeDocuments[docId]) {
      return
    }

    history.push(`/${appealId}/documents/${docId}`)
  }

  clearPlacingAnnotationState = () => {
    if (this.props.isPlacingAnnotation) {
      this.props.stopPlacingAnnotation('from-click-outside-doc')
    }
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.clearPlacingAnnotationState)
  }

  componentDidMount = () => {
    window.addEventListener('click', this.clearPlacingAnnotationState)
    if (this.props.singleDocumentMode) {
      fireSingleDocumentModeEvent()
    }
  }

  onJumpToComment = (history, appealId) => comment => () => {
    this.showPdf(history, appealId)(comment.documentId)()
    this.props.onScrollToComment(comment)
  }

  determineInitialCategoryFilter = props => {
    const queryParams = getQueryParams(props.location.search)
    const category = queryParams.category

    if (documentCategories[category]) {
      this.props.setCategoryFilter(category, true)

      // Clear out the URI query string params after we determine the initial
      // category filter so that we do not continue to attempt to set the
      // category filter every time routedPdfListView renders.
      props.location.search = ''
    }
  }

  routedPdfListView = props => {
    const {appealId} = props.match.params

    this.determineInitialCategoryFilter(props)

    return (
      <ReaderLoadingScreen
        appealDocuments={this.props.appealDocuments}
        annotations={this.props.annotations}
        appealId={appealId}
      >
        <PdfListView
          showPdf={this.showPdf(props.history, appealId)}
          sortBy={this.state.sortBy}
          selectedLabels={this.state.selectedLabels}
          isCommentLabelSelected={this.state.isCommentLabelSelected}
          documentPathBase={`/${appealId}/documents`}
          onJumpToComment={this.onJumpToComment(props.history, appealId)}
          {...props}
        />
      </ReaderLoadingScreen>
    )
  }

  routedPdfViewer = props => {
    const {appealId} = props.match.params

    return (
      <ReaderLoadingScreen
        appealDocuments={this.props.appealDocuments}
        annotations={this.props.annotations}
        appealId={appealId}
      >
        <PdfViewer
          pdfWorker={`//cdnjs.cloudflare.com/ajax/libs/pdf.js/${version}/pdf.worker.min.js`}
          allDocuments={_.values(this.props.storeDocuments)}
          showPdf={this.showPdf(props.history, appealId)}
          history={props.history}
          onJumpToComment={this.onJumpToComment(props.history, appealId)}
          documentPathBase={`/${appealId}/documents`}
          featureToggles={this.props.featureToggles}
          {...props}
        />
      </ReaderLoadingScreen>
    )
  }

  routedCaseSelect = props => (
    <CaseSelectLoadingScreen assignments={this.props.assignments}>
      <CaseSelect
        history={props.history}
        feedbackUrl={this.props.feedbackUrl}
      />
    </CaseSelectLoadingScreen>
  )

  getClaimsFolderPageTitle = appeal =>
    appeal && appeal.veteran_firstName
      ? `${appeal.veteran_firstName.charAt(0)}. \
      ${appeal.veteran_lastName}'s Claims Folder`
      : 'Claims Folder | Adjudicate Reader'

  render() {
    if (window.jwtToken) {
      this.props.receiveCurrentUser(jwt_decode(window.jwtToken))
    }

    return (
      <React.Fragment>
        <NavigationBar
          wideApp
          appName="Reader"
          logoProps={{
            accentColor: LOGO_COLORS.READER.ACCENT,
            overlapColor: LOGO_COLORS.READER.OVERLAP,
          }}
          userDisplayName={this.props.userDisplayName}
          dropdownUrls={this.props.dropdownUrls}
          defaultUrl="/"
          title="Adjudicate Reader"
        >
          <PageRoute
            exact
            title="Document Viewer | Adjudicate Reader"
            breadcrumb="Document Viewer"
            path="/:appealId/documents/:docId"
            render={this.routedPdfViewer}
          />
          <AppFrame wideApp>
            <PageRoute
              exact
              title={this.getClaimsFolderPageTitle(this.props.appeal)}
              breadcrumb="Claims Folder"
              path="/:appealId/documents"
              render={this.routedPdfListView}
            />
            <PageRoute
              exact
              path="/"
              title="Assignments | Adjudicate Reader"
              render={this.routedCaseSelect}
            />
          </AppFrame>
        </NavigationBar>
        <Footer
          wideApp
          appName="Reader"
          feedbackUrl={this.props.feedbackUrl}
          buildDate={this.props.buildDate}
        />
      </React.Fragment>
    )
  }
}

DecisionReviewer.propTypes = {
  annotations: PropTypes.any,
  appeal: PropTypes.any,
  appealDocuments: PropTypes.any,
  assignments: PropTypes.any,
  buildDate: PropTypes.any,
  dropdownUrls: PropTypes.array,
  featureToggles: PropTypes.any,
  feedbackUrl: PropTypes.any,
  history: PropTypes.any,
  isPlacingAnnotation: PropTypes.any,
  location: PropTypes.any,
  match: PropTypes.any,
  onScrollToComment: PropTypes.func,
  pdfWorker: PropTypes.string,
  setCategoryFilter: PropTypes.func,
  singleDocumentMode: PropTypes.bool,
  stopPlacingAnnotation: PropTypes.func,
  storeDocuments: PropTypes.any,
  userDisplayName: PropTypes.string,
  receiveCurrentUser: PropTypes.func,
}

const mapStateToProps = (state, props) => {
  const getAssignmentFromCaseSelect = (caseSelect, match) =>
    match && match.params.appealId
      ? _.find(caseSelect.assignments, {appeal_id: match.params.appealId})
      : null

  return {
    documentFilters: state.documentList.pdfList.filters,
    storeDocuments: state.documents,
    isPlacingAnnotation: state.annotationLayer.isPlacingAnnotation,
    appeal:
      getAssignmentFromCaseSelect(state.caseSelect, props.match) ||
      state.pdfViewer.loadedAppeal,
  }
}

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      onScrollToComment,
      setCategoryFilter,
      stopPlacingAnnotation,
      receiveCurrentUser,
    },
    dispatch,
  ),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DecisionReviewer),
)
