import createAuth0Client from '@auth0/auth0-spa-js'

export default class Auth0 {
  static auth0Client

  static async getClient(initOptions = {}) {
    if (Auth0.auth0Client) {
      return Auth0.auth0Client
    }
    Auth0.auth0Client = await createAuth0Client(initOptions)
    return Auth0.auth0Client
  }
}
