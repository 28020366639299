import PropTypes from 'prop-types'
import React from 'react'
import _ from 'lodash'

import Accordion from '../shared/Accordion'
import AccordionSection from '../shared/AccordionSection'
import EditableField from '../shared/EditableField'
import CannotSaveAlert from '../reader/CannotSaveAlert'
import {css} from 'glamor'

const rowDisplay = css({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '0px',
})
const viewedParagraphStyling = css({
  marginTop: '15px',
})

class ClaimsFolderDetails extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      docketNumber: this.props.appeal.docketNumber,
      firstName: this.props.appeal.veteran.firstName,
      middleName: this.props.appeal.veteran.middleName,
      lastName: this.props.appeal.veteran.lastName,
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.appeal.docketNumber !== prevProps.appeal.docketNumber) {
      this.setState({
        docketNumber: this.props.appeal.docketNumber,
      })
    }
  }

  render() {
    const {appeal, documents} = this.props
    const appealDoesntExist = _.isEmpty(appeal)

    const changeDocketNumber = value => {
      this.setState({
        docketNumber: value,
      })
    }

    const changeVeteranDetails = (value, name) => {
      this.setState({
        [name]: value,
      })
    }

    const resetDocketNumber = () => {
      this.setState({
        docketNumber: this.props.appeal.docketNumber,
      })
    }

    const resetVeteranDetails = field => () => {
      this.setState({
        [field]: this.props.appeal.veteran[field],
      })
    }

    const saveDocketNumber = val => {
      this.props.saveDocketNumber(
        this.props.appeal.id,
        this.props.appeal.createdAt,
        val,
      )
    }

    const saveVeteranDetails = field => val => {
      console.log('WTF')
      this.props.saveVeteranDetails(
        this.props.appeal.id,
        this.props.appeal.createdAt,
        field,
        val,
      )
    }

    const docsViewedCount = _.filter(documents, 'opened_by_current_user').length

    const veteranFullName = ({firstName, middleName, lastName}) =>
      [firstName, middleName, lastName].filter(n => 'null' !== n).join(' ')

    return (
      <div>
        <div>
          {!appealDoesntExist && (
            <h1 className="cf-push-left">{`${veteranFullName(
              appeal.veteran,
            )}'s Claims Folder`}</h1>
          )}
          <p className="cf-push-right" {...viewedParagraphStyling}>
            You&apos;ve viewed {docsViewedCount} out of {documents.length}{' '}
            documents
          </p>
        </div>
        <br />
        <br />
        <br />
        <br />
        <Accordion
          // eslint-disable-next-line react/style-prop-object
          style="bordered"
          accordion={false}
          defaultActiveKey={['claims-folder-details']}
        >
          <AccordionSection
            id="claim-folder-details-accordion"
            className="usa-grid"
            activeKey="Claims folder details"
            sectionKey={
              appealDoesntExist ? 'Loading...' : 'claims-folder-details'
            }
            disabled={appealDoesntExist}
            title={appealDoesntExist ? 'Loading...' : 'Claims folder details'}
          >
            {this.props.appeal.caseSelectError &&
              this.props.appeal.caseSelectError.visible && <CannotSaveAlert />}
            <div>
              <div {...rowDisplay}>
                <div>
                  {this.props.appeal.caseSelectError &&
                    this.props.appeal.caseSelectError.visible}
                  <EditableField
                    className="cf-pdf-meta-title"
                    value={this.state.docketNumber || ''}
                    onSave={saveDocketNumber}
                    onChange={changeDocketNumber}
                    onCancel={resetDocketNumber}
                    maxLength={50}
                    label="CAVC Docket Number"
                    strongLabel
                    name=""
                  />
                </div>
                <EditableField
                  className="cf-pdf-meta-title"
                  value={this.state.firstName || ''}
                  onSave={saveVeteranDetails('firstName')}
                  onChange={changeVeteranDetails}
                  onCancel={resetVeteranDetails('firstName')}
                  maxLength={50}
                  label="First Name"
                  strongLabel
                  name="firstName"
                />
                <EditableField
                  className="cf-pdf-meta-title"
                  value={this.state.middleName || ''}
                  onSave={saveVeteranDetails('middleName')}
                  onChange={changeVeteranDetails}
                  onCancel={resetVeteranDetails('middleName')}
                  maxLength={50}
                  label="Middle Name"
                  strongLabel
                  name="middleName"
                />
                <EditableField
                  className="cf-pdf-meta-title"
                  value={this.state.lastName || ''}
                  onSave={saveVeteranDetails('lastName')}
                  onChange={changeVeteranDetails}
                  onCancel={resetVeteranDetails('lastName')}
                  maxLength={50}
                  label="Last Name"
                  strongLabel
                  name="lastName"
                />
              </div>
            </div>
          </AccordionSection>
        </Accordion>
      </div>
    )
  }
}

ClaimsFolderDetails.propTypes = {
  appeal: PropTypes.any,
  documents: PropTypes.any,
  saveDocketNumber: PropTypes.func,
  saveVeteranDetails: PropTypes.func,
}

export default ClaimsFolderDetails
