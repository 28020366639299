import React from 'react'
import PropTypes from 'prop-types'
import Link from './Link'

class BoldOnCondition extends React.PureComponent {
  render = () =>
    this.props.condition ? (
      <strong>{this.props.children}</strong>
    ) : (
      this.props.children
    )
}

BoldOnCondition.propTypes = {
  children: PropTypes.any,
  condition: PropTypes.any,
}

export default class ViewableItemLink extends React.PureComponent {
  // Annoyingly, if we make this call in this event loop iteration, it won't follow the link.
  // Instead, we use setTimeout to force it to run at a later point.
  onClick = () => setTimeout(this.props.onOpen)

  render = () => (
    <BoldOnCondition condition={this.props.boldCondition}>
      <Link {...this.props.linkProps} onMouseUp={this.onClick}>
        {this.props.children}
      </Link>
    </BoldOnCondition>
  )
}

ViewableItemLink.propTypes = {
  boldCondition: PropTypes.any,
  children: PropTypes.any,
  linkProps: PropTypes.any,
  onOpen: PropTypes.any,
}
