import './assets/stylesheets/app.scss'
import '../node_modules/pdfjs-dist/web/pdf_viewer.css'

import React from 'react'
import ReactDOM from 'react-dom'

import App from './components/App'
import registerServiceWorker from './registerServiceWorker'

import {Auth0Provider} from './react-auth0-spa'

import history from './util/history'
import aws_config from './aws-config'

const onRedirectCallback = appState => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname,
  )
}

ReactDOM.render(
  <Auth0Provider
    domain={aws_config.Auth0.domain}
    client_id={aws_config.Auth0.clientId}
    redirect_uri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
  >
    <App />
  </Auth0Provider>,
  document.getElementById('root'),
)

registerServiceWorker()
