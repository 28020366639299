import * as Constants from './actionTypes'

export const receiveCurrentUser = decodedAuth0JWT => {
  return {
    type: Constants.RECEIVE_CURRENT_USER,
    payload: {
      email: decodedAuth0JWT.email,
      exp: decodedAuth0JWT.exp,
      roles: decodedAuth0JWT['http://adjudicate.io/user_authorization']
        ? decodedAuth0JWT['http://adjudicate.io/user_authorization'].roles
        : [],
      name: decodedAuth0JWT.name,
      nickname: decodedAuth0JWT.nickname,
      picture: decodedAuth0JWT.picture,
    },
  }
}
