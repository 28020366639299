import PropTypes from 'prop-types'
import React from 'react'
import getAppWidthStyling from '../../util/getAppWidthStyling'

const AppFrame = ({children, wideApp}) => (
  <main {...getAppWidthStyling(wideApp)}>{children}</main>
)

AppFrame.propTypes = {
  children: PropTypes.any,
  wideApp: PropTypes.any,
}

export default AppFrame
