import PropTypes from 'prop-types'
import React from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import PaginatedTable from '../shared/PaginatedTable'
import CaseSelectNewCase from './CaseSelectNewCase'

import _ from 'lodash'
import AppSegment from '../shared/AppSegment'
import Alert from '../shared/Alert'
import {css} from 'glamor'
import moment from 'moment'

import {clearSearch, clearAllFilters} from './DocumentList/DocumentListActions'
import {loadAssignments} from './CaseSelect/CaseSelectActions'
import ViewClaimsFolder from './ViewClaimsFolder'

export const COLUMN_NAMES = {
  'appeal.caseType': 'Case Type',
  'appeal.docketName': 'Docket Number',
  label: 'Task(s)',
}

class CaseSelect extends React.PureComponent {
  renderUploadDate = appeal => moment(appeal.createdAt).format('MM/DD/YYYY')

  getVeteranName = appeal =>
    `${appeal.veteran.lastName}, ${appeal.veteran.firstName}`

  getAssignmentColumn = () => [
    {
      header: 'Veteran Name',
      valueFunction: this.getVeteranName,
      label: 'Veteran Name',
      idPrefix: 'veteran',
      anyFiltersAreSet: true,
      tableData: this.props.assignments,
      columnName: 'label',
      getSortValue: appeal => appeal.veteran.lastName,
    },
    {
      header: 'CAVC Docket number',
      valueName: 'docketNumber',
      label: 'CAVC Docket number',
      idPrefix: 'docketNumber',
      anyFiltersAreSet: true,
      tableData: this.props.assignments,
      columnName: 'label',
      getSortValue: appeal => appeal.docketNumber,
    },
    {
      header: 'Uploaded Date',
      valueFunction: this.renderUploadDate,
      getSortValue: appeal => moment(appeal.createdAt),
    },
    {
      header: 'View Claims Folder',
      valueFunction: row => <ViewClaimsFolder row={row} />,
    },
  ]

  getKeyForRow = (_index, row) => row.id

  componentDidMount() {
    this.props.clearSearch()
    this.props.clearAllFilters()
  }

  componentDidUpdate(prevProps) {
    const success =
      prevProps.newCaseSubmitSuccess !== this.props.newCaseSubmitSuccess

    if (success) {
      this.props.loadAssignments()
    }
  }

  searchRowObjects = (searchQuery, rowObject) =>
    rowObject.docketNumber.toLowerCase().includes(searchQuery) ||
    rowObject.veteran.firstName.toLowerCase().includes(searchQuery) ||
    rowObject.veteran.lastName.toLowerCase().includes(searchQuery) ||
    moment(rowObject.createdAt)
      .format('MM/DD/YYYY')
      .toLowerCase()
      .includes(searchQuery)

  render() {
    if (!this.props.assignments) {
      return null
    }

    return (
      <AppSegment filledBackground>
        <div
          {...css({
            marginBottom: '10px',
          })}
        >
          {this.props.newCaseSubmitSuccess && (
            <Alert title="Case created" type="info">
              {`A new case has been created.`}
            </Alert>
          )}
          {this.props.caseDeletionError && (
            <Alert title="Case not be deleted" type="error">
              {this.props.caseDeletionError}
            </Alert>
          )}
        </div>
        <div className="section--case-select">
          <CaseSelectNewCase />
          <p className="cf-lead-paragraph" />
          <h2>Available Cases</h2>
          <PaginatedTable
            defaultSort={{
              sortAscending: false,
              sortColIdx: 2,
            }}
            className="assignment-list"
            columns={this.getAssignmentColumn}
            rowObjects={this.props.assignments}
            getKeyForRow={this.getKeyForRow}
            searchRowObjects={this.searchRowObjects}
            alternateColumnNames={COLUMN_NAMES}
            summary="Available Cases"
            enablePagination
          />
        </div>
      </AppSegment>
    )
  }
}

CaseSelect.propTypes = {
  assignments: PropTypes.any,
  clearAllFilters: PropTypes.any,
  clearSearch: PropTypes.any,
  loadAssignments: PropTypes.any,
  newCaseSubmitSuccess: PropTypes.any,
  caseDeletionError: PropTypes.string,
}

const mapStateToProps = state => ({
  ..._.pick(state.caseSelect, 'caseDeletionError'),
  ..._.pick(state.caseSelect, 'assignments'),
  ..._.pick(state.caseSelect, 'newCaseSubmitSuccess'),
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      clearSearch,
      clearAllFilters,
      loadAssignments,
    },
    dispatch,
  )

export default connect(mapStateToProps, mapDispatchToProps)(CaseSelect)
