import PropTypes from 'prop-types'
import React from 'react'
import {loadingSymbolHtml} from './RenderFunctions'
import AppSegment from './AppSegment'
import {css} from 'glamor'

const centerTextStyling = css({
  textAlign: 'center',
})

const LoadingScreen = ({spinnerColor, message}) => (
  <AppSegment filledBackground>
    <div {...centerTextStyling}>
      {loadingSymbolHtml('', '300px', spinnerColor)}
      <p>{message}</p>
    </div>
  </AppSegment>
)

LoadingScreen.propTypes = {
  message: PropTypes.any,
  spinnerColor: PropTypes.any,
}

export default LoadingScreen
