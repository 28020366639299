import React from 'react'
import PropTypes from 'prop-types'
import {Link as RouterLink} from 'react-router-dom'
import {css} from 'glamor'
import {COLORS} from '../../util/StyleConstants'

const disabledLinkStyling = css({
  color: COLORS.GREY_MEDIUM,
  ':hover': {
    color: COLORS.GREY_MEDIUM,
  },
})

const linkButtonStyling = css({
  backgroundColor: 'transparent',
  border: 'none',
  cursor: 'pointer',
  display: 'inline',
  margin: '0',
  padding: '0',

  ':hover': {
    textDecoration: 'none',
    backgroundColor: 'transparent',
  },

  ':focus': {
    textDecoration: 'none',
    backgroundColor: 'transparent',
  },
})

const matteStyling = css({
  margin: '0 8px',
  color: 'inherit',
  padding: '0',
  backgroundColor: 'transparent',
  fontSize: 'inherit',
  pointerEvents: 'all',
  lineHeight: 'normal',

  ':visited': {
    color: 'inherit',
  },

  ':hover': {
    textDecoration: 'none',
    backgroundColor: 'transparent',
    color: COLORS.PRIMARY_ALT,

    '& svg g': {
      fill: COLORS.PRIMARY_ALT,
    },
  },

  '& svg': {
    verticalAlign: 'bottom',
  },

  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflowX: 'hidden',
  maxWidth: '100%',
})

const CLASS_NAME_MAPPING = {
  primary: {
    className: 'usa-button',
    styling: {},
  },
  secondary: {
    className: 'usa-button-secondary',
    styling: {},
  },
  disabled: {
    className: 'usa-button-disabled',
    styling: {},
  },
  matte: {
    className: 'link-matte link-overflow',
    styling: matteStyling,
  },
  link: {
    className: 'link-matte',
    styling: linkButtonStyling,
  },
}

export default class Link extends React.Component {
  render() {
    const {
      ariaLabel,
      to,
      target,
      name,
      onClick,
      onMouseUp,
      href,
      button,
      children,
      disabled,
    } = this.props

    if (disabled) {
      return <span {...disabledLinkStyling}>{children}</span>
    }

    const type = button && button !== 'link' ? 'button' : null
    const styling = CLASS_NAME_MAPPING[button]
      ? CLASS_NAME_MAPPING[button].styling
      : {}
    const className = CLASS_NAME_MAPPING[button]
      ? CLASS_NAME_MAPPING[button].className
      : {}

    if (button === 'disabled') {
      return (
        <p
          type={type}
          className={CLASS_NAME_MAPPING[button].className}
          {...styling}
        >
          {children}
        </p>
      )
    }

    const commonProps = {
      ...styling,
      'aria-label': ariaLabel,
      target,
      type,
      id: name,
      className: className,
      onClick,
      onMouseUp,
    }

    if (to) {
      return (
        <RouterLink to={to} {...commonProps}>
          {children}
        </RouterLink>
      )
    }

    return (
      <a href={href} {...commonProps}>
        {children}
      </a>
    )
  }
}

Link.propTypes = {
  href: PropTypes.string,
  name: PropTypes.string,
  target: PropTypes.string,
  ariaLabel: PropTypes.string,
  to: PropTypes.string,
  button: PropTypes.string,
  onMouseUp: PropTypes.func,
  onClick: PropTypes.func,
  children: PropTypes.node,
  disabled: PropTypes.bool,
}
