/* eslint-disable max-lines */
import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {connect} from 'react-redux'

import {receiptDate} from '../reader/utils'
import Comment from './Comment'
import DocumentCategoryIcons from './DocumentCategoryIcons'
import TagTableColumn from './TagTableColumn'
import Table from '../shared/Table'
import Button from '../shared/Button'
import CommentIndicator from './CommentIndicator'
import DropdownFilter from './DropdownFilter'
import {bindActionCreators} from 'redux'
import Highlight from '../shared/Highlight'
import {
  setDocListScrollPosition,
  changeSortState,
  clearTagFilters,
  clearCategoryFilters,
  setTagFilter,
  setCategoryFilter,
  toggleDropdownFilterVisibility,
  setJumpToOriginalPageNumber,
  jumpToPageOriginalPdf,
  showJumpToOriginalPageNumberError,
} from '../reader/DocumentList/DocumentListActions'
import {getAnnotationsPerDocument} from './selectors'
import {
  SortArrowUp,
  SortArrowDown,
  DoubleArrow,
} from '../shared/RenderFunctions'
import DocCategoryPicker from './DocCategoryPicker'
import DocTagPicker from './DocTagPicker'
import FilterIcon from '../shared/FilterIcon'
import LastReadIndicator from './LastReadIndicator'
import DocTypeColumn from './DocTypeColumn'

const NUMBER_OF_COLUMNS = 8
const ENTER_KEY = 'Enter'
const PAGE_OFF_SET_ONE = 1

export const getRowObjects = (documents, annotationsPerDocument) => {
  return documents.reduce((acc, doc) => {
    acc.push(doc)
    const docHasComments = _.size(annotationsPerDocument[doc.id])

    if (docHasComments && doc.listComments) {
      acc.push({
        ...doc,
        isComment: true,
      })
    }

    return acc
  }, [])
}

class DocumentsTable extends React.Component {
  componentDidMount() {
    if (this.props.pdfList.scrollTop) {
      this.tbodyElem.scrollTop = this.props.pdfList.scrollTop

      if (this.lastReadIndicatorElem) {
        const lastReadBoundingRect =
          this.lastReadIndicatorElem.getBoundingClientRect()
        const tbodyBoundingRect = this.tbodyElem.getBoundingClientRect()
        const lastReadIndicatorIsInView =
          tbodyBoundingRect.top <= lastReadBoundingRect.top &&
          lastReadBoundingRect.bottom <= tbodyBoundingRect.bottom

        if (!lastReadIndicatorIsInView) {
          const rowWithLastRead = _.find(this.tbodyElem.children, tr =>
            tr.querySelector(`#${this.lastReadIndicatorElem.id}`),
          )

          this.tbodyElem.scrollTop +=
            rowWithLastRead.getBoundingClientRect().top - tbodyBoundingRect.top
        }
      }
    }
  }

  componentWillUnmount() {
    this.props.setDocListScrollPosition(this.tbodyElem.scrollTop)
  }

  getTbodyRef = elem => (this.tbodyElem = elem)
  getLastReadIndicatorRef = elem => (this.lastReadIndicatorElem = elem)
  getCategoryFilterIconRef = categoryFilterIcon =>
    (this.categoryFilterIcon = categoryFilterIcon)
  getTagFilterIconRef = tagFilterIcon => (this.tagFilterIcon = tagFilterIcon)
  toggleCategoryDropdownFilterVisiblity = () =>
    this.props.toggleDropdownFilterVisibility('category')
  toggleTagDropdownFilterVisiblity = () =>
    this.props.toggleDropdownFilterVisibility('tag')

  getKeyForRow = (index, {isComment, id}) => {
    return isComment ? `${id}-comment` : id
  }

  // eslint-disable-next-line max-statements
  getDocumentColumns = row => {
    const sortArrowIcon = this.props.docFilterCriteria.sort.sortAscending ? (
      <SortArrowUp />
    ) : (
      <SortArrowDown />
    )
    const notSortedIcon = <DoubleArrow />

    const anyFiltersSet = filterType =>
      Boolean(_.some(this.props.docFilterCriteria[filterType]))

    const anyCategoryFiltersAreSet = anyFiltersSet('category')
    const anyTagFiltersAreSet = anyFiltersSet('tag')

    // We have blank headers for the comment indicator and label indicator columns.
    // We use onMouseUp instead of onClick for filename event handler since OnMouseUp
    // is triggered when a middle mouse button is clicked while onClick isn't.
    if (row && row.isComment) {
      return [
        {
          valueFunction: doc => {
            const comments = _.sortBy(
              this.props.annotationsPerDocument[doc.id],
              ['page', 'y'],
            )
            const commentNodes = comments.map((comment, commentIndex) => {
              return (
                <Comment
                  key={comment.uuid}
                  id={`comment${doc.id}-${commentIndex}`}
                  selected={false}
                  page={comment.page}
                  onJumpToComment={this.props.onJumpToComment(comment)}
                  uuid={comment.uuid}
                  date={comment.relevantDate}
                  horizontalLayout
                >
                  {comment.comment}
                </Comment>
              )
            })

            return (
              <ul className="cf-no-styling-list" aria-label="Document comments">
                {commentNodes}
              </ul>
            )
          },
          span: _.constant(NUMBER_OF_COLUMNS),
        },
      ]
    }

    const isCategoryDropdownFilterOpen = _.get(this.props.pdfList, [
      'dropdowns',
      'category',
    ])

    const isTagDropdownFilterOpen = _.get(this.props.pdfList, [
      'dropdowns',
      'tag',
    ])

    return [
      {
        cellClass: 'last-read-column',
        valueFunction: doc => (
          <LastReadIndicator
            docId={doc.id}
            getRef={this.getLastReadIndicatorRef}
          />
        ),
      },
      {
        cellClass: 'categories-column',
        header: (
          <div id="categories-header">
            Categories{' '}
            <FilterIcon
              label="Filter by category"
              idPrefix="category"
              getRef={this.getCategoryFilterIconRef}
              selected={
                isCategoryDropdownFilterOpen || anyCategoryFiltersAreSet
              }
              handleActivate={this.toggleCategoryDropdownFilterVisiblity}
            />
            {isCategoryDropdownFilterOpen && (
              <DropdownFilter
                clearFilters={this.props.clearCategoryFilters}
                name="category"
                isClearEnabled={anyCategoryFiltersAreSet}
                handleClose={this.toggleCategoryDropdownFilterVisiblity}
              >
                <DocCategoryPicker
                  categoryToggleStates={this.props.docFilterCriteria.category}
                  handleCategoryToggle={this.props.setCategoryFilter}
                />
              </DropdownFilter>
            )}
          </div>
        ),
        valueFunction: doc => <DocumentCategoryIcons doc={doc} />,
      },
      {
        cellClass: 'receipt-date-column',
        header: (
          <Button
            name="Receipt Date"
            id="receipt-date-header"
            classNames={['cf-document-list-button-header']}
            onClick={() => this.props.changeSortState('receiptDate')}
          >
            Receipt Date{' '}
            {this.props.docFilterCriteria.sort.sortBy === 'receiptDate'
              ? sortArrowIcon
              : notSortedIcon}
          </Button>
        ),
        valueFunction: doc => (
          <span className="document-list-receipt-date">
            <Highlight>{receiptDate(doc.receiptDate)}</Highlight>
          </span>
        ),
      },
      {
        cellClass: 'doc-type-column',
        header: (
          <Button
            id="type-header"
            name="Document"
            classNames={['cf-document-list-button-header']}
            onClick={() => this.props.changeSortState('filename')}
          >
            Document{' '}
            {this.props.docFilterCriteria.sort.sortBy === 'filename'
              ? sortArrowIcon
              : notSortedIcon}
          </Button>
        ),
        valueFunction: doc => (
          <DocTypeColumn
            doc={doc}
            documentPathBase={this.props.documentPathBase}
          />
        ),
      },
      {
        cellClass: 'tags-column',
        header: (
          <div id="tags-header" className="document-list-header-issue-tags">
            Issue Tags{' '}
            <FilterIcon
              label="Filter by tag"
              idPrefix="tag"
              getRef={this.getTagFilterIconRef}
              selected={isTagDropdownFilterOpen || anyTagFiltersAreSet}
              handleActivate={this.toggleTagDropdownFilterVisiblity}
            />
            {isTagDropdownFilterOpen && (
              <DropdownFilter
                clearFilters={this.props.clearTagFilters}
                name="tag"
                isClearEnabled={anyTagFiltersAreSet}
                handleClose={this.toggleTagDropdownFilterVisiblity}
              >
                <DocTagPicker
                  tags={this.props.tagOptions}
                  tagToggleStates={this.props.docFilterCriteria.tag}
                  handleTagToggle={this.props.setTagFilter}
                />
              </DropdownFilter>
            )}
          </div>
        ),
        valueFunction: doc => {
          return <TagTableColumn tags={doc.tags} />
        },
      },
      {
        cellClass: 'page-count-column', // Add your own class
        header: (
          <Button
            id="page-count-header"
            name="Page Count"
            classNames={['cf-document-list-button-header']}
            onClick={() => this.props.changeSortState('pageCount')}
          >
            Doc Page Count{' '}
            {this.props.docFilterCriteria.sort.sortBy === 'pageCount'
              ? sortArrowIcon
              : notSortedIcon}
          </Button>
        ),
        valueFunction: doc => (
          <span>{doc.endPageNum - doc.startPageNum + 1}</span>
        ),
      },
      {
        cellClass: 'page-range-column', // Add your own class
        header: (
          <Button
            id="page-range-header"
            name="RBA Page Range"
            classNames={['cf-document-list-button-header']}
            onClick={() => this.props.changeSortState('pageRange')}
          >
            RBA Page Range{' '}
            {this.props.docFilterCriteria.sort.sortBy === 'pageRange'
              ? sortArrowIcon
              : notSortedIcon}
          </Button>
        ),
        valueFunction: doc => (
          <span>{doc.startPageNum + ' - ' + doc.endPageNum}</span>
        ),
      },
      {
        cellClass: 'comments-column',
        header: (
          <div id="comments-header" className="document-list-header-comments">
            Comments
          </div>
        ),
        valueFunction: doc => <CommentIndicator docId={doc.id} />,
      },
    ]
  }

  handleKeyPress = event => {
    if (event.key === ENTER_KEY) {
      const pageNumber = event.target.value
      const doc = _.find(
        this.props.documents,
        doc => pageNumber >= doc.startPageNum && pageNumber <= doc.endPageNum,
      )

      if (!doc) {
        this.props.showJumpToOriginalPageNumberError()
      } else {
        this.props.showPdf(doc.id)()
        this.props.jumpToPageOriginalPdf(
          pageNumber - doc.startPageNum + PAGE_OFF_SET_ONE,
          doc.id,
        )
      }
    }
  }

  render() {
    const rowObjects = getRowObjects(
      this.props.documents,
      this.props.annotationsPerDocument,
    )

    return (
      <div>
        <div className="table-container">
          <Table
            columns={this.getDocumentColumns}
            rowObjects={rowObjects}
            summary="Document list"
            className="documents-table"
            headerClassName="cf-document-list-header-row"
            bodyClassName="cf-document-list-body"
            rowsPerRowObject={2}
            tbodyId="documents-table-body"
            tbodyRef={this.getTbodyRef}
            getKeyForRow={this.getKeyForRow}
          />
        </div>
      </div>
    )
  }
}

DocumentsTable.propTypes = {
  annotationsPerDocument: PropTypes.any,
  changeSortState: PropTypes.any,
  clearCategoryFilters: PropTypes.any,
  clearTagFilters: PropTypes.any,
  docFilterCriteria: PropTypes.any,
  documentPathBase: PropTypes.any,
  documents: PropTypes.arrayOf(PropTypes.object).isRequired,
  errors: PropTypes.any,
  jumpToPageNumberOriginalPdf: PropTypes.any,
  jumpToPageOriginalPdf: PropTypes.any,
  lastReadDocId: PropTypes.string,
  onJumpToComment: PropTypes.func,
  pdfList: PropTypes.shape({
    lastReadDocId: PropTypes.string,
    scrollTop: PropTypes.any,
  }),

  setCategoryFilter: PropTypes.any,
  setDocListScrollPosition: PropTypes.any,
  setJumpToOriginalPageNumber: PropTypes.any,
  setTagFilter: PropTypes.any,
  showJumpToOriginalPageNumberError: PropTypes.any,
  showPdf: PropTypes.any,
  sortBy: PropTypes.string,
  tagOptions: PropTypes.any,
  toggleDropdownFilterVisibility: PropTypes.any,
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setDocListScrollPosition,
      clearTagFilters,
      clearCategoryFilters,
      setTagFilter,
      changeSortState,
      toggleDropdownFilterVisibility,
      setCategoryFilter,
      setJumpToOriginalPageNumber,
      jumpToPageOriginalPdf,
      showJumpToOriginalPageNumberError,
    },
    dispatch,
  )

const mapStateToProps = state => ({
  annotationsPerDocument: getAnnotationsPerDocument(state),
  ..._.pick(
    state.documentList,
    'docFilterCriteria',
    'pdfList',
    'errors',
    'jumpToPageNumberOriginalPdf',
  ),
  ..._.pick(state.pdfViewer, 'tagOptions'),
})

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsTable)
