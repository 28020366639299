import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {connect} from 'react-redux'

import {getAnnotationsPerDocument} from './selectors'
import Comment from './Comment'
import Table from '../shared/Table'

export const getRowObjects = (
  documents,
  annotationsPerDocument,
  searchQuery = '',
) => {
  const groupedAnnotations = _(annotationsPerDocument)
    .map(notes =>
      notes.map(note => {
        const {filename, serialized_receipt_date} = documents.filter(
          doc => doc.id === note.documentId,
        )[0]

        return _.extend({}, note, {
          filename: filename,
          serialized_receipt_date,
        })
      }),
    )
    .flatten()
    .filter(note => {
      if (!searchQuery) {
        return true
      }

      const query = new RegExp(searchQuery, 'i')

      return note.comment.match(query) || note.filename.match(query)
    })
    .groupBy(note =>
      note.relevantDate ? 'relevantDate' : 'serialized_receipt_date',
    )
    .value()

  // groupBy returns { relevantDate: [notes w/relevantDate], serialized_receipt_date: [notes w/out] }
  return _.sortBy(groupedAnnotations.relevantDate, 'relevantDate').concat(
    _.sortBy(
      groupedAnnotations.serialized_receipt_date,
      'serialized_receipt_date',
    ),
  )
}

getRowObjects.propTypes = {
  filter: PropTypes.any,
}

class CommentsTable extends React.PureComponent {
  getCommentColumn = () => [
    {
      header: 'Sorted by relevant date',
      valueFunction: (comment, idx) => (
        <Comment
          key={comment.uuid}
          id={`comment-${idx}`}
          page={comment.page}
          onJumpToComment={this.props.onJumpToComment(comment)}
          uuid={comment.uuid}
          date={comment.relevantDate}
          filename={comment.filename}
          horizontalLayout
        >
          {comment.comment}
        </Comment>
      ),
    },
  ]

  getKeyForRow = (rowNumber, object) => object.uuid

  render() {
    const {documents, annotationsPerDocument, searchQuery} = this.props

    return (
      <div>
        <Table
          columns={this.getCommentColumn}
          rowObjects={getRowObjects(
            documents,
            annotationsPerDocument,
            searchQuery,
          )}
          className="documents-table full-width"
          bodyClassName="cf-document-list-body"
          getKeyForRow={this.getKeyForRow}
          headerClassName="comments-table-header"
          rowClassNames={_.constant('borderless')}
        />
      </div>
    )
  }
}

CommentsTable.propTypes = {
  annotationsPerDocument: PropTypes.any,
  documents: PropTypes.arrayOf(PropTypes.object).isRequired,
  onJumpToComment: PropTypes.func.isRequired,
  searchQuery: PropTypes.any,
}

const mapStateToProps = state => ({
  annotationsPerDocument: getAnnotationsPerDocument(state),
  ..._.pick(state.documentList.docFilterCriteria, 'searchQuery'),
})

export default connect(mapStateToProps)(CommentsTable)
