/** Document Related */
export const ASSIGN_DOCUMENTS = 'ASSIGN_DOCUMENTS'
export const RECEIVE_DOCUMENTS = 'RECEIVE_DOCUMENTS'
export const ROTATE_PDF_DOCUMENT = 'ROTATE_PDF_DOCUMENT'
export const SELECT_CURRENT_VIEWER_PDF = 'SELECT_CURRENT_VIEWER_PDF'
export const TOGGLE_DOCUMENT_CATEGORY_FAIL = 'TOGGLE_DOCUMENT_CATEGORY_FAIL'
export const TOGGLE_DOCUMENT_CATEGORY = 'TOGGLE_DOCUMENT_CATEGORY'
export const SET_VIEWING_DOCUMENTS_OR_COMMENTS =
  'SET_VIEWING_DOCUMENTS_OR_COMMENTS'
export const TOGGLE_COMMENT_LIST = 'TOGGLE_COMMENT_LIST'
export const CLOSE_DOCUMENT_UPDATED_MODAL = 'CLOSE_DOCUMENT_UPDATED_MODAL'

// Description
export const CHANGE_PENDING_DOCUMENT_DESCRIPTION =
  'CHANGE_PENDING_DOCUMENT_DESCRIPTION'
export const RESET_PENDING_DOCUMENT_DESCRIPTION =
  'RESET_PENDING_DOCUMENT_DESCRIPTION'
export const SAVE_DOCUMENT_DESCRIPTION_SUCCESS =
  'SAVE_DOCUMENT_DESCRIPTION_SUCCESS'

// Tags
export const REQUEST_NEW_TAG_CREATION_SUCCESS =
  'REQUEST_NEW_TAG_CREATION_SUCCESS'
export const REQUEST_NEW_TAG_CREATION_FAILURE =
  'REQUEST_NEW_TAG_CREATION_FAILURE'
export const REQUEST_NEW_TAG_CREATION = 'REQUEST_NEW_TAG_CREATION'
export const REQUEST_REMOVE_TAG = 'REQUEST_REMOVE_TAG'
export const REQUEST_REMOVE_TAG_SUCCESS = 'REQUEST_REMOVE_TAG_SUCCESS'
export const REQUEST_REMOVE_TAG_FAILURE = 'REQUEST_REMOVE_TAG_FAILURE'
export const RECEIVE_TAGS = 'RECEIVE_TAGS'

export const ROTATION_INCREMENTS = 90
export const COMPLETE_ROTATION = 360
