import React from 'react'
import {useAuth0} from '../../react-auth0-spa'

const SignOut = props => {
  const {logout} = useAuth0()

  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin,
    })

  return (
    <a href="#" onClick={() => logoutWithRedirect()}>
      Sign Out
    </a>
  )
}

export default SignOut
