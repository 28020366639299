import _ from 'lodash'
import {newContext} from 'immutability-helper'

export const update = newContext()

update.extend('$unset', (keyToUnset, obj) => obj && _.omit(obj, keyToUnset))

export default {
  changeFieldValue: (state, action) => ({
    ...state,
    [action.payload.field]: action.payload.value,
  }),

  changeObjectInArray: (array, action) => {
    return array.map((object, index) => {
      if (index !== action.index) {
        // This isn't the item we care about - keep it as-is
        return object
      }

      // Otherwise, this is the one we want - return an updated value
      return {
        ...object,
        ...action.values,
      }
    })
  },
}

export const setErrorMessageState = (
  state,
  errorType,
  isVisible,
  errorsVarName,
  errorMsg = null,
) =>
  update(state, {
    [errorsVarName]: {
      [errorType]: {
        visible: {$set: isVisible},
        message: {$set: isVisible ? errorMsg : null},
      },
    },
  })

export const hideErrorMessage = (
  state,
  errorType,
  errorsVarName,
  errorMsg = null,
) => setErrorMessageState(state, errorType, false, errorsVarName, errorMsg)
export const showErrorMessage = (
  state,
  errorType,
  errorsVarName,
  errorMsg = null,
) => setErrorMessageState(state, errorType, true, errorsVarName, errorMsg)
