import * as Constants from './actionTypes'
import {update} from '../../../util/ReducerUtil'
import _ from 'lodash'

export const initialState = {
  caseSelectCriteria: {
    searchQuery: '',
  },
  assignments: [],
  assignmentsLoaded: false,
  showNewCaseModal: false,
  newCaseSubmitSuccess: false,
  newCaseSubmitFailed: false,
  loading: false,
  caseDeletionError: '',
}

export const caseSelectReducer = (state = initialState, action = {}) => {
  let assignments = []

  switch (action.type) {
    case Constants.LOADING_DATA_OR_RESULTS:
      return update(state, {
        loading: {
          $set: true,
        },
      })
    case Constants.RECEIVE_ASSIGNMENTS:
      if (action.payload.assignments) {
        assignments = action.payload.assignments.map(assignment => {
          assignment.caseSelectError = {
            docketNumber: {
              visible: false,
              message: null,
            },
          }
          return assignment
        })
      }
      return update(state, {
        assignments: {
          $set: assignments,
        },
        assignmentsLoaded: {
          $set: true,
        },
      })
    case Constants.SET_VIEWED_ASSIGNMENT:
      return update(state, {
        assignments: {
          $apply: existingAssignments =>
            existingAssignments.map(assignment => ({
              ...assignment,
              viewed:
                assignment.id === action.payload.appealId
                  ? true
                  : assignment.viewed,
            })),
        },
      })
    case Constants.SHOW_NEW_CASE_MODAL:
      return update(state, {
        showNewCaseModal: {
          $set: true,
        },
      })
    case Constants.REQUEST_REMOVE_APPEAL_REQUEST:
      return update(state, {
        assignments: {
          $apply: existingAssignments =>
            existingAssignments.map(assignment => ({
              ...assignment,
              deleting:
                assignment.id === action.payload.appealId ? true : false,
            })),
        },
      })
    case Constants.REQUEST_REMOVE_APPEAL_SUCCESS:
      return update(state, {
        assignments: {
          $apply: existingAssignments =>
            _.compact(
              existingAssignments.map(assignment => {
                let newAssignment
                if (assignment.id !== action.payload.appealId) {
                  newAssignment = assignment
                }
                return newAssignment
              }),
            ),
        },
      })
    case Constants.REQUEST_REMOVE_APPEAL_FAILURE:
      return update(state, {
        caseDeletionError: {
          $set: action.payload.errorMessage,
        },
        assignments: {
          $apply: existingAssignments =>
            existingAssignments.map(assignment => ({
              ...assignment,
              deleting: false,
            })),
        },
      })
    case Constants.CLEAR_NEW_CASE_MODAL:
      return update(state, {
        showNewCaseModal: {
          $set: false,
        },
      })
    case Constants.NEW_CASE_SUBMIT_SUCCESS:
      return update(state, {
        newCaseSubmitSuccess: {
          $set: true,
        },
        newCaseSubmitFailed: {
          $set: false,
        },
        loading: {
          $set: false,
        },
      })
    case Constants.NEW_CASE_SUBMIT_FAILED:
      return update(state, {
        newCaseSubmitSuccess: {
          $set: false,
        },
        newCaseSubmitFailed: {
          $set: true,
        },
        loading: {
          $set: false,
        },
      })
    case Constants.NEW_CASE_HIDE_SUCCESS:
      return update(state, {
        newCaseSubmitSuccess: {
          $set: false,
        },
        newCaseSubmitFailed: {
          $set: false,
        },
      })
    case Constants.UPDATE_MODIFIED_APPEAL:
      return update(state, {
        assignments: {
          $apply: existingAssignments =>
            existingAssignments.map(assignment => {
              if (assignment.id === action.payload.appeal.id) {
                assignment = action.payload.appeal
              }
              return assignment
            }),
        },
      })
    default:
      return state
  }
}

export default caseSelectReducer
