import React, {useEffect, useState} from 'react'
import {useAuth0} from '../react-auth0-spa'

import {css} from 'glamor'
import AWS from 'aws-sdk'

import BaseContainer from './containers/BaseContainer'
import * as Sentry from '@sentry/browser'
import ErrorBoundary from './shared/ErrorBoundary'
import LoadingScreen from './shared/LoadingScreen'
import {LOGO_COLORS} from '../constants/AppConstants'
import aws_config from '../aws-config'

const mainAppStyling = css({
  width: '85vw',
  marginLeft: 'auto',
  marginRight: 'auto',
})

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_URL || '',
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT || 'unknown',
})

const App = () => {
  const {loginWithRedirect, loading, isAuthenticated, getIdTokenClaims} =
    useAuth0()
  const [token, setToken] = useState(null)

  useEffect(() => {
    const getToken = async () => {
      const refreshingAuth0Token = window.refreshingAuth0Token

      if (!refreshingAuth0Token) {
        window.refreshingAuth0Token = true
        const claims = await getIdTokenClaims()
        const token = claims.__raw
        setToken(token)
        window.jwtToken = token
      }
    }

    const getAWSCreds = async () => {
      if (!AWS.config.credentials) {
        AWS.config.region = aws_config.Auth.region
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: aws_config.Auth.identityPoolId,
          Logins: {
            [aws_config.Auth0.domain]: token,
          },
        })
      }

      const needsRefresh = await AWS.config.credentials.needsRefresh()
      const refreshingAWSCreds = window.refreshingAWSCreds

      if (needsRefresh && !refreshingAWSCreds) {
        window.refreshingAWSCreds = true
        await AWS.config.credentials.refreshPromise()
      }
    }

    const fn = async () => {
      if (loading || isAuthenticated) {
        if (!token && isAuthenticated) {
          await getToken()
        } else if (
          token &&
          (!AWS.config.credentials ||
            (AWS.config.credentials && AWS.config.credentials.expired))
        ) {
          await getAWSCreds()
        }
        return
      }

      await loginWithRedirect({
        appState: {targetUrl: window.location.pathname},
      })
    }
    fn()
  }, [loading, isAuthenticated, loginWithRedirect, getIdTokenClaims, token])

  if (loading || !isAuthenticated) {
    return (
      <main {...mainAppStyling}>
        <LoadingScreen
          spinnerColor={LOGO_COLORS.READER.ACCENT}
          message="Authenticating..."
        />
      </main>
    )
  }
  return (
    <ErrorBoundary>
      <div>
        <BaseContainer page={'Reader'} />
      </div>
    </ErrorBoundary>
  )
}

export default App
