import AWSAppSyncClient, {AUTH_TYPE} from 'aws-appsync'
import aws_config from '../aws-config'
import Auth0 from './auth0'

export default new AWSAppSyncClient(
  {
    url: aws_config.aws_appsync_graphqlEndpoint,
    region: aws_config.aws_appsync_region,
    disableOffline: true,
    auth: {
      type: AUTH_TYPE.OPENID_CONNECT,
      jwtToken: async () => {
        const client = await Auth0.getClient()
        const claims = await client.getIdTokenClaims()
        return claims.__raw
      },
    },
  },
  {connectToDevTools: true},
)
