import _ from 'lodash'
import * as Sentry from '@sentry/browser'
import gql from 'graphql-tag'
import AWSAppSync from '../../../clients/aws_app_sync'

import * as Constants from './actionTypes'
import {CATEGORIES} from '../analytics'
import {selectAnnotation} from '../../reader/AnnotationLayer/AnnotationActions'
import {updateModifiedAppeal} from '../../reader/CaseSelect/CaseSelectActions'

export const collectAllTags = documents => ({
  type: Constants.COLLECT_ALL_TAGS_FOR_OPTIONS,
  payload: documents,
})

/** Jump To Page **/

export const jumpToPage = (pageNumber, docId) => ({
  type: Constants.JUMP_TO_PAGE,
  payload: {
    pageNumber,
    docId,
  },
})

export const resetJumpToPage = () => ({
  type: Constants.RESET_JUMP_TO_PAGE,
})

export const handleSelectCommentIcon = comment => dispatch => {
  // Normally, we would not want to fire two actions here.
  // I think that SCROLL_TO_SIDEBAR_COMMENT needs cleanup
  // more generally, so I'm just going to leave it alone for now,
  // and hack this in here.
  dispatch(selectAnnotation(comment.id))
  dispatch({
    type: Constants.SCROLL_TO_SIDEBAR_COMMENT,
    payload: {
      scrollToSidebarComment: comment,
    },
  })
}

/** Scrolling **/

export const setDocScrollPosition = scrollTop => ({
  type: Constants.SET_DOC_SCROLL_POSITION,
  payload: {
    scrollTop,
  },
})

/** Getting Appeal Details **/

export const onReceiveAppealDetails = appeal => ({
  type: Constants.RECEIVE_APPEAL_DETAILS,
  payload: {
    appeal,
  },
})

export const onAppealDetailsLoadingFail = (failedToLoad = true) => ({
  type: Constants.RECEIVE_APPEAL_DETAILS_FAILURE,
  payload: {
    failedToLoad,
  },
})

export const fetchAppealDetails = appealId => async dispatch => {
  const query = gql(`
    query getAppeal {
      appeal(id: "Appeal:${appealId}") {
        id
        createdAt
        docketNumber
        veteran {
          firstName
          lastName
          middleName
        }
      }
    }
  `)

  try {
    const {data} = await AWSAppSync.query({query})
    dispatch(onReceiveAppealDetails(data.appeal))
  } catch (err) {
    Sentry.captureException(err)
    console.log(err)
    dispatch(onAppealDetailsLoadingFail())
  }
}

export const setLoadedAppealId = appealId => ({
  type: Constants.SET_LOADED_APPEAL_ID,
  payload: {
    appealId,
  },
})

/** Sidebar and Accordion controls **/

export const setOpenedAccordionSections = (
  openedAccordionSections,
  prevSections,
) => ({
  type: Constants.SET_OPENED_ACCORDION_SECTIONS,
  payload: {
    openedAccordionSections,
  },
  meta: {
    analytics: triggerEvent => {
      const addedSectionKeys = _.difference(
        openedAccordionSections,
        prevSections,
      )
      const removedSectionKeys = _.difference(
        prevSections,
        openedAccordionSections,
      )

      addedSectionKeys.forEach(newKey =>
        triggerEvent(
          CATEGORIES.VIEW_DOCUMENT_PAGE,
          'opened-accordion-section',
          newKey,
        ),
      )
      removedSectionKeys.forEach(oldKey =>
        triggerEvent(
          CATEGORIES.VIEW_DOCUMENT_PAGE,
          'closed-accordion-section',
          oldKey,
        ),
      )
    },
  },
})

export const togglePdfSidebar = () => ({
  type: Constants.TOGGLE_PDF_SIDEBAR,
  meta: {
    analytics: {
      category: CATEGORIES.VIEW_DOCUMENT_PAGE,
      action: 'toggle-pdf-sidebar',
      label: nextState =>
        nextState.pdfViewer.hidePdfSidebar ? 'hide' : 'show',
    },
  },
})

export const toggleSearchBar = () => ({
  type: Constants.TOGGLE_SEARCH_BAR,
})

export const showSearchBar = () => ({
  type: Constants.SHOW_SEARCH_BAR,
})

export const hideSearchBar = () => ({
  type: Constants.HIDE_SEARCH_BAR,
})

export const resetSidebarErrors = () => ({
  type: Constants.RESET_PDF_SIDEBAR_ERRORS,
})

export const handleFinishScrollToSidebarComment = () => ({
  type: Constants.SCROLL_TO_SIDEBAR_COMMENT,
  payload: {
    scrollToSidebarComment: null,
  },
})

export const setZoomLevel = scale => ({
  type: Constants.SET_ZOOM_LEVEL,
  payload: {
    scale,
  },
})

/** Windowing parameters **/

export const handleSetOverscanValue = overscanValue => ({
  type: Constants.SET_WINDOWING_OVERSCAN,
  payload: {
    overscanValue,
  },
})

export const onCavcDetailsLoadingFailed = id => ({
  type: Constants.SAVE_CAVC_DOCKET_NUMBER_FAILURE,
  payload: {
    id,
  },
})

export const onVeteranDetailsLoadingFailed = id => ({
  type: Constants.SAVE_VETERAN_DETAILS_FAILURE,
  payload: {
    id,
  },
})

// syncing changes from the loadedAppeal back to assignments
// in the CaseSelect reducer.
const updateCaseSelectAppeal = (dispatch, getState) => {
  const {
    pdfViewer: {loadedAppeal},
  } = getState()
  dispatch(updateModifiedAppeal(loadedAppeal))
}

export const saveCavDocketNumber =
  (appealId, createdAt, value) => async (dispatch, getState) => {
    const mutation = gql(`
    mutation editAppeal($appealId: String!, $createdAt: String!, $appealEditInput: AppealEditInput) {
      editAppeal(appealId: $appealId, createdAt: $createdAt, input: $appealEditInput) {
        id
        docketNumber
      }
    }
  `)
    try {
      const {data} = await AWSAppSync.mutate({
        mutation,
        variables: {
          appealId,
          createdAt,
          appealEditInput: {
            docketNumber: value,
          },
        },
      })

      dispatch({
        type: Constants.SAVE_CAVC_DOCKET_NUMBER_SUCCESS,
        payload: {
          id: appealId,
          value: data.editAppeal.docketNumber,
        },
      })
      updateCaseSelectAppeal(dispatch, getState)
    } catch (err) {
      console.log('err' + err)
      Sentry.captureException(err)
      dispatch(onCavcDetailsLoadingFailed(appealId))
    }
  }

export const saveVeteranDetails =
  (appealId, createdAt, name, value) => async (dispatch, getState) => {
    const mutation = gql(`
    mutation editAppeal($appealId: String!, $createdAt: String!, $appealEditInput: AppealEditInput) {
      editAppeal(appealId: $appealId, createdAt: $createdAt, input: $appealEditInput) {
        id
        veteran {
          firstName,
          middleName,
          lastName
        }
      }
    }
  `)
    console.log(name)
    console.log(value)
    try {
      const {data} = await AWSAppSync.mutate({
        mutation,
        variables: {
          appealId,
          createdAt,
          appealEditInput: {
            veteran: {
              [name]: value,
            },
          },
        },
      })

      dispatch({
        type: Constants.SAVE_VETERAN_DETAILS_SUCCESS,
        payload: {
          id: appealId,
          value: data.editAppeal.veteran,
        },
      })
      updateCaseSelectAppeal(dispatch, getState)
    } catch (err) {
      console.log('err' + err)
      Sentry.captureException(err)
      dispatch(onVeteranDetailsLoadingFailed(appealId))
    }
  }
