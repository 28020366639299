export const SET_OPENED_ACCORDION_SECTIONS = 'SET_OPENED_ACCORDION_SECTIONS'
export const COLLECT_ALL_TAGS_FOR_OPTIONS = 'COLLECT_ALL_TAGS_FOR_OPTIONS'
export const TOGGLE_PDF_SIDEBAR = 'TOGGLE_PDF_SIDEBAR'
export const SET_DOC_SCROLL_POSITION = 'SET_DOC_SCROLL_POSITION'
export const TOGGLE_SEARCH_BAR = 'TOGGLE_SEARCH_BAR'
export const SHOW_SEARCH_BAR = 'SHOW_SEARCH_BAR'
export const HIDE_SEARCH_BAR = 'HIDE_SEARCH_BAR'
export const JUMP_TO_PAGE = 'JUMP_TO_PAGE'
export const RESET_JUMP_TO_PAGE = 'RESET_JUMP_TO_PAGE'
export const JUMP_TO_PAGE_ORIGINAL_PDF = 'JUMP_TO_PAGE_ORIGINAL_PDF'
export const RESET_PDF_SIDEBAR_ERRORS = 'RESET_PDF_SIDEBAR_ERRORS'
export const SET_ZOOM_LEVEL = 'SET_ZOOM_LEVEL'

// errors
export const HIDE_ERROR_MESSAGE = 'HIDE_ERROR_MESSAGE'
export const SHOW_ERROR_MESSAGE = 'SHOW_ERROR_MESSAGE'

export const COMMENT_ACCORDION_KEY = 'Comments'
export const SCROLL_TO_SIDEBAR_COMMENT = 'SCROLL_TO_SIDEBAR_COMMENT'

// receive appeal information
export const RECEIVE_APPEAL_DETAILS = 'RECEIVE_APPEAL_DETAILS'
export const RECEIVE_APPEAL_DETAILS_FAILURE = 'RECEIVE_APPEAL_DETAILS_FAILURE'
export const SET_LOADED_APPEAL_ID = 'SET_LOADED_APPEAL_ID'

// Windowing
export const SET_WINDOWING_OVERSCAN = 'SET_WINDOWING_OVERSCAN'

// Saving values in case details
export const SAVE_CAVC_DOCKET_NUMBER_SUCCESS = 'SAVE_CAVC_DOCKET_NUMBER_SUCCESS'
export const SAVE_CAVC_DOCKET_NUMBER_FAILURE = 'SAVE_CAVC_DOCKET_NUMBER_FAILURE'

export const SAVE_VETERAN_DETAILS_SUCCESS = 'SAVE_VETERAN_DETAILS_SUCCESS'
export const SAVE_VETERAN_DETAILS_FAILURE = 'SAVE_VETERAN_DETAILS_FAILURE'
