import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import Highlighter from 'react-highlight-words'
import _ from 'lodash'

export class Highlight extends PureComponent {
  render() {
    const {searchQuery} = this.props
    let highlightedData

    if (typeof this.props.children == 'string') {
      highlightedData = (
        <Highlighter
          searchWords={_.union([searchQuery], searchQuery.split(' '))}
          textToHighlight={this.props.children}
          autoEscape
        />
      )
    } else {
      highlightedData = this.props.children
    }
    return <span>{highlightedData}</span>
  }
}

Highlight.propTypes = {
  children: PropTypes.string,
  searchQuery: PropTypes.string.isRequired,
}

Highlight.defaultProps = {
  searchQuery: '',
  children: '',
}

const mapStateToProps = state => ({
  searchQuery: state.documentList.docFilterCriteria.searchQuery,
})

export default connect(mapStateToProps)(Highlight)
