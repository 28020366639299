import React from 'react'
import PropTypes from 'prop-types'
import {css} from 'glamor'
import Link from './Link'

const styling = css({
  display: 'none',
})

export default class FileUpload extends React.Component {
  onUploadEvent = event => {
    const file = event.target.files[0]

    this.props.onChange({
      file,
      fileName: file.name,
    })
  }

  render() {
    return (
      <div className="cf-form-textinput">
        <label htmlFor={this.props.id}>
          {this.props.value && (
            <b>
              {this.props.value.fileName}
              &nbsp;
            </b>
          )}
          <Link onChange={this.props.onChange}>
            {this.props.value
              ? this.props.postUploadText
              : this.props.preUploadText}
          </Link>
        </label>
        <div {...styling}>
          <input
            type="file"
            accept={this.props.fileType}
            id={this.props.id}
            onChange={this.onUploadEvent}
          />
        </div>
      </div>
    )
  }
}

FileUpload.propTypes = {
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  preUploadText: PropTypes.string.isRequired,
  postUploadText: PropTypes.string.isRequired,
  fileType: PropTypes.string,
  value: PropTypes.object,
}
