import React from 'react'
import PropTypes from 'prop-types'
import {css} from 'glamor'
import _ from 'lodash'

export const COLORS = {
  PRIMARY_ALT: '#02bfe7',

  GREY_LIGHT: '#d6d7d9',
  GREY_MEDIUM: '#757575',
  GREY_DARK: '#323a45',

  WHITE: '#fff',
}

const dropdownFilterViewListStyle = css({
  margin: 0,
})
const dropdownFilterViewListItemStyle = css({
  padding: '14px',
  ':hover': {
    backgroundColor: '#5b616b',
    color: COLORS.WHITE,
  },
})

class DropdownFilter extends React.PureComponent {
  constructor() {
    super()
    this.state = {
      rootElemWidth: null,
    }
  }

  render() {
    const {children, name} = this.props

    // Some of the filter names are camelCase, which would be displayed to the user.
    // To make this more readable, convert the camelCase text to regular casing.
    const displayName = _.capitalize(_.upperCase(name))

    const rel = {
      position: 'relative',
    }

    return (
      <div style={rel}>
        <div
          className="cf-dropdown-filter"
          style={{top: '10px'}}
          ref={rootElem => {
            this.rootElem = rootElem
          }}
        >
          {this.props.addClearFiltersRow && (
            <div className="cf-filter-option-row">
              <button
                className="cf-text-button"
                onClick={this.props.clearFilters}
                disabled={!this.props.isClearEnabled}
              >
                <div className="cf-clear-filter-button-wrapper">
                  Clear {displayName} filter
                </div>
              </button>
            </div>
          )}
          {React.cloneElement(React.Children.only(children), {
            dropdownFilterViewListStyle,
            dropdownFilterViewListItemStyle,
          })}
        </div>
      </div>
    )
  }

  componentDidMount() {
    document.addEventListener('click', this.onGlobalClick, true)
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onGlobalClick)
  }

  onGlobalClick = event => {
    if (!this.rootElem) {
      return
    }

    const clickIsInsideThisComponent = this.rootElem.contains(event.target)

    if (!clickIsInsideThisComponent) {
      this.props.handleClose()
    }
  }
}

DropdownFilter.propTypes = {
  addClearFiltersRow: PropTypes.bool,
  children: PropTypes.node,
  clearFilters: PropTypes.func,
  handleClose: PropTypes.func,
  isClearEnabled: PropTypes.bool,
  name: PropTypes.any,
}

export default DropdownFilter
