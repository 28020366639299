import gql from 'graphql-tag'
import * as Sentry from '@sentry/browser'
import * as Constants from './actionTypes'
import AWS from 'aws-sdk'

import AWSAppSync from '../../../clients/aws_app_sync'
import aws_config from '../../../aws-config'

export const showNewCaseModal = () => ({
  type: Constants.SHOW_NEW_CASE_MODAL,
})

export const clearNewCaseModal = () => ({
  type: Constants.CLEAR_NEW_CASE_MODAL,
})

export const setViewedAssignment = appealId => ({
  type: Constants.SET_VIEWED_ASSIGNMENT,
  payload: {
    appealId,
  },
})

export const onReceiveAssignments = assignments => ({
  type: Constants.RECEIVE_ASSIGNMENTS,
  payload: {
    assignments,
  },
})

export const requestRemoveAppeal = (appealId, createdAt) => async dispatch => {
  const mutation = gql(`
    mutation removeAppeal($appealId: String!, $createdAt: String!) {
      removeAppeal(appealId: $appealId, createdAt: $createdAt) {
        id
      }
    }
  `)

  try {
    dispatch(requestRemoveAppealRequest(appealId))
    await AWSAppSync.mutate({
      mutation,
      variables: {
        appealId,
        createdAt,
      },
    })

    dispatch(requestRemoveAppealSuccess(appealId))
  } catch (err) {
    Sentry.captureException(err)
    dispatch(requestRemoveAppealFailed(appealId))
  }
}

export const requestRemoveAppealRequest = appealId => ({
  type: Constants.REQUEST_REMOVE_APPEAL_REQUEST,
  payload: {appealId},
})

export const requestRemoveAppealSuccess = appealId => ({
  type: Constants.REQUEST_REMOVE_APPEAL_SUCCESS,
  payload: {appealId},
})

export const requestRemoveAppealFailed = appealId => ({
  type: Constants.REQUEST_REMOVE_APPEAL_FAILURE,
  payload: {
    appealId,
    errorMessage: 'This case could not be deleted, please try again.',
  },
})

export const onNewCaseSubmitSuccess = () => ({
  type: Constants.NEW_CASE_SUBMIT_SUCCESS,
})

export const onNewCaseSubmitFailed = () => ({
  type: Constants.NEW_CASE_SUBMIT_FAILED,
})

export const hideNewCaseSuccessAlert = () => ({
  type: Constants.NEW_CASE_HIDE_SUCCESS,
})

export const loadingDataOrResults = () => ({
  type: Constants.LOADING_DATA_OR_RESULTS,
})

export const loadAssignments = () => async dispatch => {
  try {
    const {data} = await AWSAppSync.query({
      query: gql(`
        query {
          appeals {
            items {
              id
              docketNumber
              createdAt
              veteran {
                firstName
                middleName
                lastName
              }
            }
          }
        }
      `),
    })

    dispatch(onReceiveAssignments(data.appeals.items))
  } catch (err) {
    Sentry.captureException(err)
    console.log(err)
    throw err
  }
}

export const submitANewCase = newCaseForm => async dispatch => {
  let fileName = `${newCaseForm.docketNumber}__${newCaseForm.lastName}__`

  if (newCaseForm.middleName) {
    fileName += `${newCaseForm.middleName}__`
  }

  fileName += `${newCaseForm.firstName}`
  try {
    dispatch(loadingDataOrResults())
    await new AWS.S3()
      .putObject({
        Bucket: aws_config.UploadBucket.bucket,
        Key: fileName + '.pdf',
        Body: newCaseForm.caseFile.file,
        Metadata: {
          docketNumber: newCaseForm.docketNumber,
          firstName: newCaseForm.firstName,
          middleName: newCaseForm.middleName || '',
          lastName: newCaseForm.lastName,
        },
      })
      .promise()

    dispatch(onNewCaseSubmitSuccess())
    dispatch(clearNewCaseModal())
    setTimeout(() => {
      dispatch(hideNewCaseSuccessAlert())
    }, 5000)
  } catch (err) {
    Sentry.captureException(err)
    console.log(err)
    dispatch(onNewCaseSubmitFailed())
  }
}

export const updateModifiedAppeal = appeal => ({
  type: Constants.UPDATE_MODIFIED_APPEAL,
  payload: {
    appeal,
  },
})
