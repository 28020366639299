import PropTypes from 'prop-types'
import React, {PureComponent} from 'react'
import {bindActionCreators} from 'redux'
import ToggleButton from '../shared/ToggleButton'
import Button from '../shared/Button'
import {connect} from 'react-redux'
import {setViewingDocumentsOrComments} from '../reader/DocumentList/DocumentListActions'
import {DOCUMENTS_OR_COMMENTS_ENUM} from './DocumentList/actionTypes'

class DocumentsCommentsButton extends PureComponent {
  render = () => (
    <div className="cf-documents-comments-control">
      <span className="cf-show-all-label">Show all:</span>
      <ToggleButton
        active={this.props.viewingDocumentsOrComments}
        onClick={this.props.setViewingDocumentsOrComments}
      >
        <Button name={DOCUMENTS_OR_COMMENTS_ENUM.DOCUMENTS}>Documents</Button>
        <Button name={DOCUMENTS_OR_COMMENTS_ENUM.COMMENTS}>Comments</Button>
      </ToggleButton>
    </div>
  )
}

DocumentsCommentsButton.propTypes = {
  setViewingDocumentsOrComments: PropTypes.any,
  viewingDocumentsOrComments: PropTypes.any,
}

export default connect(
  state => ({
    viewingDocumentsOrComments: state.documentList.viewingDocumentsOrComments,
  }),
  dispatch =>
    bindActionCreators(
      {
        setViewingDocumentsOrComments,
      },
      dispatch,
    ),
)(DocumentsCommentsButton)
