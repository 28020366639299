// Annotations

// Delete
export const REQUEST_DELETE_ANNOTATION_SUCCESS =
  'REQUEST_DELETE_ANNOTATION_SUCCESS'
export const REQUEST_DELETE_ANNOTATION = 'REQUEST_DELETE_ANNOTATION'
export const REQUEST_DELETE_ANNOTATION_FAILURE =
  'REQUEST_DELETE_ANNOTATION_FAILURE'

// Edit
export const REQUEST_EDIT_ANNOTATION_FAILURE = 'REQUEST_EDIT_ANNOTATION_FAILURE'
export const REQUEST_EDIT_ANNOTATION_SUCCESS = 'REQUEST_EDIT_ANNOTATION_SUCCESS'
export const REQUEST_EDIT_ANNOTATION = 'REQUEST_EDIT_ANNOTATION'
export const CANCEL_EDIT_ANNOTATION = 'CANCEL_EDIT_ANNOTATION'
export const START_EDIT_ANNOTATION = 'START_EDIT_ANNOTATION'
export const UPDATE_ANNOTATION_CONTENT = 'UPDATE_ANNOTATION_CONTENT'
export const UPDATE_ANNOTATION_RELEVANT_DATE = 'UPDATE_ANNOTATION_RELEVANT_DATE'

// create
export const REQUEST_CREATE_ANNOTATION = 'REQUEST_CREATE_ANNOTATION'
export const REQUEST_CREATE_ANNOTATION_SUCCESS =
  'REQUEST_CREATE_ANNOTATION_SUCCESS'
export const REQUEST_CREATE_ANNOTATION_FAILURE =
  'REQUEST_CREATE_ANNOTATION_FAILURE'

// placement
export const SHOW_PLACE_ANNOTATION_ICON = 'SHOW_PLACE_ANNOTATION_ICON'
export const START_PLACING_ANNOTATION = 'START_PLACING_ANNOTATION'
export const STOP_PLACING_ANNOTATION = 'STOP_PLACING_ANNOTATION'
export const SELECT_ANNOTATION = 'SELECT_ANNOTATION'

// Move
export const REQUEST_MOVE_ANNOTATION = 'REQUEST_MOVE_ANNOTATION'
export const REQUEST_MOVE_ANNOTATION_SUCCESS = 'REQUEST_MOVE_ANNOTATION_SUCCESS'
export const REQUEST_MOVE_ANNOTATION_FAILURE = 'REQUEST_MOVE_ANNOTATION_FAILURE'
export const PLACE_ANNOTATION = 'PLACE_ANNOTATION'

export const COMMENT_ACCORDION_KEY = 'COMMENT_ACCORDION_KEY'
export const UPDATE_NEW_ANNOTATION_CONTENT = 'UPDATE_NEW_ANNOTATION_CONTENT'
export const UPDATE_NEW_ANNOTATION_RELEVANT_DATE =
  'UPDATE_NEW_ANNOTATION_RELEVANT_DATE'
export const RECEIVE_ANNOTATIONS = 'RECEIVE_ANNOTATIONS'

// annotation modal control
export const OPEN_ANNOTATION_DELETE_MODAL = 'OPEN_ANNOTATION_DELETE_MODAL'
export const CLOSE_ANNOTATION_DELETE_MODAL = 'CLOSE_ANNOTATION_DELETE_MODAL'
