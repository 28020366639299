const awsconfig = {
  Auth: {
    identityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
    region: 'us-east-1',
    mandatorySignIn: false,
  },
  UploadBucket: {
    bucket: process.env.REACT_APP_AWS_USER_FILES_S3_BUCKET_UPLOAD,
    region: 'us-east-1',
  },
  DownloadBucket: {
    bucket: process.env.REACT_APP_AWS_USER_FILES_S3_BUCKET_DOWNLOAD,
    region: 'us-east-1',
  },
  aws_appsync_graphqlEndpoint:
    process.env.REACT_APP_AWS_APPSYNC_GRAPHQL_ENDPOINT,
  aws_appsync_region: 'us-east-1',
  aws_appsync_authenticationType: 'OPENID_CONNECT',
  Auth0: {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  },
}

export default awsconfig
