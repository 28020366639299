/** Document Table */
export const SET_SORT = 'SET_SORT'
export const SET_DOC_LIST_SCROLL_POSITION = 'SET_DOC_LIST_SCROLL_POSITION'
export const SET_VIEWING_DOCUMENTS_OR_COMMENTS =
  'SET_VIEWING_DOCUMENTS_OR_COMMENTS'
export const RECEIVE_MANIFESTS = 'RECEIVE_MANIFESTS'
export const LAST_READ_DOCUMENT = 'LAST_READ_DOCUMENT'
export const SET_JUMP_TO_PAGE_ORIGINAL_PDF = 'SET_JUMP_TO_PAGE_ORIGINAL_PDF'

// Search
export const SET_SEARCH = 'SET_SEARCH'
export const CLEAR_ALL_SEARCH = 'CLEAR_ALL_SEARCH'

// Filters
export const SET_TAG_FILTER = 'SET_TAG_FILTER'
export const CLEAR_TAG_FILTER = 'CLEAR_TAG_FILTER'
export const CLEAR_ALL_FILTERS = 'CLEAR_ALL_FILTERS'
export const CLEAR_CATEGORY_FILTER = 'CLEAR_CATEGORY_FILTER'
export const SET_CATEGORY_FILTER = 'SET_CATEGORY_FILTER'
export const UPDATE_FILTERED_RESULTS = 'UPDATE_FILTERED_RESULTS'
export const TOGGLE_FILTER_DROPDOWN = 'TOGGLE_FILTER_DROPDOWN'

// errors
export const DOC_LIST_HIDE_ERROR_MESSAGE = 'DOC_LIST_HIDE_ERROR_MESSAGE'
export const DOC_LIST_SHOW_ERROR_MESSAGE = 'DOC_LIST_SHOW_ERROR_MESSAGE'

// Constants
export const DOCUMENTS_OR_COMMENTS_ENUM = {
  DOCUMENTS: 'documents',
  COMMENTS: 'comments',
}
