import React from 'react'
import PropTypes from 'prop-types'
import Link from './Link'
import Breadcrumbs from './Breadcrumbs'
import {css} from 'glamor'
import {COLORS, STYLES} from '../../util/StyleConstants'
import getAppWidthStyling from '../../util/getAppWidthStyling'
import SignOut from './SignOut'
import {useAuth0} from '../../react-auth0-spa'

const lineHeight = {lineHeight: '4em'}

const h1Styling = css({
  margin: 0,
  display: 'inline-block',
  fontSize: '1.7rem',
  ...lineHeight,
  '& > a': {
    color: `${COLORS.GREY_DARK} !important`,
    paddingLeft: '.3em',
  },
})

const pushLeftStyling = css({
  display: 'flex',
  alignItems: 'center',
  ...lineHeight,
})

const headerStyling = css({
  background: COLORS.WHITE,
  ...lineHeight,
})

const clearingDivStyling = css({
  borderBottom: `1px solid ${COLORS.GREY_LIGHT}`,
  clear: 'both',
})

const topMessageStyling = css({
  marginBottom: 0,
})

const NavigationBar = props => {
  const {appName, topMessage, wideApp} = props

  const {isAuthenticated} = useAuth0()

  return (
    <div>
      <header {...headerStyling}>
        <div>
          <div {...getAppWidthStyling(wideApp)}>
            <span className="cf-push-left" {...pushLeftStyling}>
              <h1 {...h1Styling}>
                <Link id="cf-logo-link" to="/">
                  Adjudicate.io V2
                </Link>
                {appName && (
                  <Link to={null}>
                    <h2
                      id="page-title"
                      className="cf-application-title"
                      {...STYLES.APPLICATION_TITLE}
                    >
                      &gt; {appName}
                    </h2>
                  </Link>
                )}
              </h1>
              <Breadcrumbs>{props.children}</Breadcrumbs>
              {topMessage && (
                <h2
                  className="cf-application-title"
                  {...STYLES.APPLICATION_TITLE}
                  {...topMessageStyling}
                >
                  &nbsp; | &nbsp; {topMessage}
                </h2>
              )}
            </span>
            <span className="cf-push-right">
              {isAuthenticated && <SignOut />}
            </span>
          </div>
        </div>
        <div {...clearingDivStyling}> </div>
      </header>
      {props.children}
    </div>
  )
}

NavigationBar.defaultProps = {
  extraBanner: null,
}

NavigationBar.propTypes = {
  appName: PropTypes.string.isRequired,
  children: PropTypes.any,
  defaultUrl: PropTypes.string,
  extraBanner: PropTypes.element,
  target: PropTypes.string,
  title: PropTypes.string.isRequired,
  topMessage: PropTypes.any,
  userDisplayName: PropTypes.string,
  wideApp: PropTypes.any,
}

export default NavigationBar
