import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import PropTypes from 'prop-types'
import _ from 'lodash'

import {makeGetAnnotationsByDocumentId} from '../reader/selectors'
import CommentIcon from './CommentIcon'
import {
  keyOfAnnotation,
  pageNumberOfPageIndex,
  getPageCoordinatesOfMouseEvent,
} from './utils'
import {handleSelectCommentIcon} from '../reader/PdfViewer/PdfViewerActions'

import {
  placeAnnotation,
  showPlaceAnnotationIcon,
  requestMoveAnnotation,
} from '../reader/AnnotationLayer/AnnotationActions'

const DIV_STYLING = {
  width: '100%',
  height: '100%',
  zIndex: 10,
  position: 'relative',
}

// The comment layer is a div on top of a page that draws the comment
// icons on the page. It is the div that receives the onClick
// events when placing new comments. It is also the div that displays
// the PDF text elements. We need text elements in this div since it
// is the largest zIndex div, and blocks lower divs from receiving click events.
// The text layer needs to be click-able so users can highlight/copy/paste them.
class CommentLayer extends PureComponent {
  constructor(props) {
    super(props)

    this.commentLayerDiv = null
  }

  onPageClick = event => {
    if (!this.props.isPlacingAnnotation) {
      return
    }

    const {x, y} = getPageCoordinatesOfMouseEvent(
      event,
      this.commentLayerDiv.getBoundingClientRect(),
      this.props.scale,
      this.props.rotation,
    )

    this.props.placeAnnotation(
      pageNumberOfPageIndex(this.props.pageIndex),
      {
        xPosition: x,
        yPosition: y,
      },
      this.props.documentId,
    )
  }

  getPlacingAnnotation = () => {
    if (
      this.props.placingAnnotationIconPageCoords &&
      this.props.isPlacingAnnotation
    ) {
      return [
        {
          temporaryId: 'placing-annotation-icon',
          page: pageNumberOfPageIndex(
            this.props.placingAnnotationIconPageCoords.pageIndex,
          ),
          isPlacingAnnotationIcon: true,
          ..._.pick(this.props.placingAnnotationIconPageCoords, 'x', 'y'),
        },
      ]
    }

    return []
  }
  // Move the comment when it's dropped on a page
  // eslint-disable-next-line max-statements
  onCommentDrop = event => {
    const dragAndDropPayload = event.dataTransfer.getData('text')

    // Fix for Firefox browsers. Need to call preventDefault() so that
    // Firefox does not navigate to anything.com.
    // Issue 2969
    event.preventDefault()

    let dragAndDropData

    // Anything can be dragged and dropped. If the item that was
    // dropped doesn't match what we expect, we just silently ignore it.

    try {
      dragAndDropData = JSON.parse(dragAndDropPayload)

      if (!dragAndDropData.iconCoordinates || !dragAndDropData.uuid) {
        return
      }
    } catch (err) {
      if (err instanceof SyntaxError) {
        return
      }
      throw err
    }

    const coordinates = getPageCoordinatesOfMouseEvent(
      event,
      this.commentLayerDiv.getBoundingClientRect(),
      this.props.scale,
      this.props.rotation,
    )

    const droppedAnnotation = {
      ...this.props.allAnnotations[dragAndDropData.uuid],
      ...coordinates,
      page: pageNumberOfPageIndex(this.props.pageIndex),
    }

    this.props.requestMoveAnnotation(this.props.document, droppedAnnotation)
  }

  mouseListener = event => {
    if (this.props.isPlacingAnnotation) {
      const pageCoords = getPageCoordinatesOfMouseEvent(
        event,
        this.commentLayerDiv.getBoundingClientRect(),
        this.props.scale,
        this.props.rotation,
      )

      this.props.showPlaceAnnotationIcon(this.props.pageIndex, pageCoords)
    }
  }

  // To specify the component as droppable, we need to preventDefault on the event.
  onPageDragOver = event => event.preventDefault()

  getCommentLayerDivRef = ref => (this.commentLayerDiv = ref)

  getAnnotationsForPage = () =>
    this.props.comments
      .concat(this.getPlacingAnnotation())
      .filter(
        comment => comment.page === pageNumberOfPageIndex(this.props.pageIndex),
      )

  getCommentIcons = () =>
    this.getAnnotationsForPage().map(comment => (
      <CommentIcon
        comment={comment}
        rotation={-this.props.rotation}
        position={{
          x: comment.x * this.props.scale,
          y: comment.y * this.props.scale,
        }}
        key={keyOfAnnotation(comment)}
        onClick={
          comment.isPlacingAnnotationIcon
            ? _.noop
            : this.props.handleSelectCommentIcon
        }
      />
    ))

  render() {
    // Instead of redrawing the text on scales, we just do a CSS transform which is faster.
    const TEXT_LAYER_STYLING = {
      width: `${this.props.dimensions.width}px`,
      height: `${this.props.dimensions.height}px`,
      transform: `scale(${this.props.scale})`,
      transformOrigin: 'left top',
    }

    return (
      <div
        id={`comment-layer-${this.props.pageIndex}-${this.props.file}`}
        style={DIV_STYLING}
        onDragOver={this.onPageDragOver}
        onDrop={this.onCommentDrop}
        onClick={this.onPageClick}
        onMouseMove={this.mouseListener}
        ref={this.getCommentLayerDivRef}
      >
        {this.props.isVisible && this.getCommentIcons()}
        <div
          style={TEXT_LAYER_STYLING}
          ref={this.props.getTextLayerRef}
          className="textLayer"
        />
      </div>
    )
  }
}

CommentLayer.propTypes = {
  allAnnotations: PropTypes.any,
  comment: PropTypes.string,
  comments: PropTypes.arrayOf(
    PropTypes.shape({
      comment: PropTypes.string,
      uuid: PropTypes.string,
      page: PropTypes.number,
      x: PropTypes.number,
      y: PropTypes.number,
    }),
  ),
  dimensions: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  document: PropTypes.object,
  documentId: PropTypes.string,
  file: PropTypes.string,
  getTextLayerRef: PropTypes.func,
  handleSelectCommentIcon: PropTypes.func,
  height: PropTypes.number,
  isPlacingAnnotation: PropTypes.bool,
  isVisible: PropTypes.bool,
  page: PropTypes.number,
  pageIndex: PropTypes.number,
  placeAnnotation: PropTypes.any,
  placingAnnotationIconPageCoords: PropTypes.object,
  requestMoveAnnotation: PropTypes.any,
  rotation: PropTypes.number,
  scale: PropTypes.number,
  showPlaceAnnotationIcon: PropTypes.any,
  uuid: PropTypes.string,
  width: PropTypes.number,
  x: PropTypes.number,
  y: PropTypes.number,
}

const mapStateToProps = (state, ownProps) => ({
  ..._.pick(state.annotationLayer, 'placingAnnotationIconPageCoords'),
  comments: makeGetAnnotationsByDocumentId(state)(ownProps.documentId),
  isPlacingAnnotation: state.annotationLayer.isPlacingAnnotation,
  allAnnotations: state.annotationLayer.annotations,
  document: _.find(state.documents, {id: ownProps.documentId}),
  rotation: _.get(state.documents, [ownProps.documentId, 'rotation']),
})

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      placeAnnotation,
      handleSelectCommentIcon,
      requestMoveAnnotation,
      showPlaceAnnotationIcon,
    },
    dispatch,
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(CommentLayer)
