import React, {PureComponent} from 'react'
import {reloadPage, receiptDate} from '../reader/utils'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import EditableField from '../shared/EditableField'
import Link from '../shared/Link'
import {
  saveDocumentDescription,
  changePendingDocDescription,
  resetPendingDocDescription,
} from './Documents/DocumentsActions'

import LoadingMessage from '../shared/LoadingMessage'
import Alert from '../shared/Alert'

class SideBarDocumentInformation extends PureComponent {
  render() {
    const {appeal} = this.props
    let renderComponent

    if (this.props.didLoadAppealFail) {
      renderComponent = (
        <Alert title="Unable to retrieve claim details" type="error">
          Please{' '}
          <Link name="backToClaimsFolder" button="link" onClick={reloadPage}>
            refresh this page
          </Link>{' '}
          or try again later.
        </Alert>
      )
    } else if (_.isEmpty(appeal)) {
      renderComponent = <LoadingMessage message="Loading details..." />
    } else {
      renderComponent = (
        <div>
          <p className="cf-pdf-meta-title">
            <strong>CAVC Docket number:</strong> {appeal.docketNumber}
          </p>
        </div>
      )
    }

    const docDescriptionFieldText = _.isUndefined(
      this.props.doc.pendingDescription,
    )
      ? this.props.doc.description
      : this.props.doc.pendingDescription

    return (
      <div className="cf-sidebar-document-information">
        <p className="cf-pdf-meta-title cf-pdf-cutoff">
          <strong>Document Type: </strong>
          <span title={this.props.doc.type} className="cf-document-type">
            {this.props.doc.filename}
          </span>
        </p>
        <EditableField
          className="cf-pdf-meta-title"
          value={docDescriptionFieldText || ''}
          onSave={this.saveDocDescription}
          onChange={this.changePendingDescription}
          onCancel={this.resetPendingDescription}
          maxLength={50}
          label="Document Description"
          strongLabel
          name="document_description"
          errorMessage={
            this.props.error.visible ? this.props.error.message : null
          }
        />
        <p className="cf-pdf-meta-title">
          <strong>Receipt Date:</strong>{' '}
          {receiptDate(this.props.doc.receiptDate)}
        </p>
        <hr />
        {renderComponent}
      </div>
    )
  }

  changePendingDescription = description =>
    this.props.changePendingDocDescription(this.props.doc.id, description)
  resetPendingDescription = () =>
    this.props.resetPendingDocDescription(this.props.doc.id)
  saveDocDescription = description =>
    this.props.saveDocumentDescription(this.props.doc, description)
}

SideBarDocumentInformation.propTypes = {
  appeal: PropTypes.object.isRequired,
  changePendingDocDescription: PropTypes.any,
  didLoadAppealFail: PropTypes.any,
  doc: PropTypes.any,
  error: PropTypes.any,
  resetPendingDocDescription: PropTypes.any,
  saveDocumentDescription: PropTypes.any,
}

const mapStateToProps = state => ({
  didLoadAppealFail: state.pdfViewer.didLoadAppealFail,
  error: state.pdfViewer.pdfSideBarError.description,
})

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      changePendingDocDescription,
      resetPendingDocDescription,
      saveDocumentDescription,
    },
    dispatch,
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SideBarDocumentInformation)
