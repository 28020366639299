import * as Constants from './actionTypes'
import {update} from '../../../util/ReducerUtil'

const initialState = {
  currentUser: {
    email: '',
    exp: null,
    roles: [],
    name: '',
    nickname: '',
    picture: '',
  },
}

const authReducer = (state = initialState, action = {}) => {
  let currentUser = {}

  switch (action.type) {
    case Constants.RECEIVE_CURRENT_USER:
      currentUser = {...action.payload}
      return update(state, {
        currentUser: {
          $set: currentUser,
        },
      })
    default:
      return state
  }
}

export default authReducer
