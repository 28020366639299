import React from 'react'
import PropTypes from 'prop-types'
import {closeIcon, loadingSymbolHtml} from './RenderFunctions'
import {COLORS} from '../../util/StyleConstants'
import Button from './Button'
import classnames from 'classnames'
import _ from 'lodash'
import uuid from 'uuid'

export default class SearchBar extends React.Component {
  onChange = event => {
    this.props.onChange(event.target.value)
  }

  // A "search" event occurs when a user finishes typing
  // a search query. This is 500ms after the last character
  // typed or when focus is lost.
  onSearch = () => {
    if (this.props.value && this.props.recordSearch) {
      this.props.recordSearch(this.props.value)
    }
  }

  onSubmit = () => {
    if (this.props.onSubmit) {
      this.props.onSubmit(this.props.value)
    }
  }

  handleKeyPress = event => {
    if (event.key === 'Enter') {
      this.onSubmit()
    }
  }

  clearSearchCallback() {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout)
      this.searchTimeout = null

      return true
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.clearSearchCallback()

      this.searchTimeout = setTimeout(() => {
        this.onSearch()
        this.searchTimeout = null
      }, 500)
    }
  }

  onBlur = () => {
    if (this.clearSearchCallback()) {
      this.onSearch()
    }
  }

  setInputRef = node => (this.input = node)

  setInputFocus = () => this.input.focus()
  releaseInputFocus = () => this.input.blur()

  clearInput = () => (this.input.value = '')

  getInternalField = (spinnerColor = COLORS.WHITE) => {
    if (this.props.loading) {
      return (
        <div className="search-text-spinner">
          {loadingSymbolHtml('', '25px', spinnerColor)}
        </div>
      )
    }

    return (
      <input
        id="search-internal-text"
        type="text"
        value={this.props.internalText}
        onClick={this.setInputFocus}
        className="cf-search-internal-text"
        readOnly
      />
    )
  }

  render() {
    let {
      id,
      value,
      loading,
      onClearSearch,
      isSearchAhead,
      size,
      title,
      onSubmit,
      submitUsingEnterKey,
      placeholder,
      internalText,
      spinnerColor,
      hideClearSearchButton,
    } = this.props

    id = id || uuid.v4()

    const hasInternalText = !_.isUndefined(internalText)

    const searchTypeClasses = classnames('usa-search', {
      'usa-search-big': size === 'big',
      'usa-search-small': size === 'small',
      'cf-search-ahead': isSearchAhead,
      'cf-has-internal-text': hasInternalText,
    })

    const buttonClassNames = classnames({
      'usa-sr-only': size === 'small',
    })

    const label = classnames({
      'usa-search-big': size === 'big',
      'usa-search-small': size === 'small',
    })

    const searchClasses = classnames('cf-search-input-with-close', {
      'cf-search-with-internal-text': hasInternalText,
    })

    return (
      <span className={searchTypeClasses} role="search">
        <label className={title ? label : 'usa-sr-only'} htmlFor={id}>
          {title || 'Search small'}
        </label>
        <input
          ref={this.setInputRef}
          className={searchClasses}
          id={id}
          onChange={this.onChange}
          onBlur={this.onBlur}
          type="search"
          name="search"
          onKeyPress={
            submitUsingEnterKey ? this.handleKeyPress : this.props.onKeyPress
          }
          placeholder={placeholder}
          value={value}
        />
        {hasInternalText && (
          <div>
            <label className="usa-sr-only" htmlFor="search-internal-text">
              Search Result Count
            </label>
            {this.getInternalField(spinnerColor)}
          </div>
        )}
        {!hideClearSearchButton && _.size(value) > 0 && (
          <Button
            ariaLabel="clear search"
            name="clear search"
            classNames={['cf-pdf-button cf-search-close-icon']}
            onClick={onClearSearch}
          >
            {closeIcon()}
          </Button>
        )}
        {!isSearchAhead && (
          <Button
            name={`search-${id}`}
            onClick={onSubmit ? this.onSubmit : null}
            on
            type="submit"
            loading={loading}
          >
            <span className={buttonClassNames}>Search</span>
          </Button>
        )}
      </span>
    )
  }
}

SearchBar.propTypes = {
  analyticsCategory: PropTypes.string,
  id: PropTypes.string,
  internalText: PropTypes.string,
  isSearchAhead: PropTypes.any,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  onClearSearch: PropTypes.func,
  onClick: PropTypes.func,
  onKeyPress: PropTypes.func,
  onSubmit: PropTypes.func,
  placeholder: PropTypes.any,
  hideClearSearchButton: PropTypes.bool,
  recordSearch: PropTypes.func,
  size: PropTypes.string,
  spinnerColor: PropTypes.string,
  submitUsingEnterKey: PropTypes.bool,
  title: PropTypes.string,
  value: PropTypes.string,
}
