import React from 'react'
import PropTypes from 'prop-types'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import _ from 'lodash'

import LastRetrievalInfo from './LastRetrievalInfo'
import AppSegment from '../shared/AppSegment'
import DocumentListHeader from './DocumentListHeader'
import ClaimsFolderDetails from './ClaimsFolderDetails'
import DocumentsTable from './DocumentsTable'
import CommentsTable from './CommentsTable'
import {getFilteredDocuments} from './selectors'
import NoSearchResults from './NoSearchResults'
import {
  fetchAppealDetails,
  onReceiveAppealDetails,
  saveCavDocketNumber,
  saveVeteranDetails,
} from '../reader/PdfViewer/PdfViewerActions'
import {shouldFetchAppeal} from '../reader/utils'
import {DOCUMENTS_OR_COMMENTS_ENUM} from './DocumentList/actionTypes'

export class PdfListView extends React.Component {
  componentDidMount() {
    if (
      shouldFetchAppeal(this.props.appeal, this.props.match.params.appealId)
    ) {
      // if the appeal is fetched through case selected appeals, re-use that existing appeal
      // information.
      this.props.fetchAppealDetails(this.props.match.params.appealId)

      // if appeal is loaded from the assignments and it matches the appeal id
      // in the url
    } else if (this.props.appeal.id === this.props.match.params.appealId) {
      this.props.onReceiveAppealDetails(this.props.appeal)
    }
  }

  saveDocketNumber = (id, createdAt, val) => {
    this.props.saveCavDocketNumber(id, createdAt, val)
  }

  saveVeteranDetails = (id, createdAt, name, val) => {
    this.props.saveVeteranDetails(id, createdAt, name, val)
  }

  render() {
    const noDocuments =
      !_.size(this.props.documents) &&
      _.size(this.props.docFilterCriteria.searchQuery) > 0
    let tableView
    if (noDocuments) {
      tableView = <NoSearchResults />
    } else if (
      this.props.viewingDocumentsOrComments ===
      DOCUMENTS_OR_COMMENTS_ENUM.COMMENTS
    ) {
      tableView = (
        <CommentsTable
          documents={this.props.documents}
          onJumpToComment={this.props.onJumpToComment}
        />
      )
    } else {
      tableView = (
        <DocumentsTable
          documents={this.props.documents}
          documentPathBase={this.props.documentPathBase}
          onJumpToComment={this.props.onJumpToComment}
          sortBy={this.props.sortBy}
          docFilterCriteria={this.props.docFilterCriteria}
          showPdf={this.props.showPdf}
        />
      )
    }

    return (
      <div>
        <AppSegment filledBackground>
          <div className="section--document-list">
            <ClaimsFolderDetails
              saveDocketNumber={this.saveDocketNumber}
              saveVeteranDetails={this.saveVeteranDetails}
              appeal={this.props.appeal}
              documents={this.props.documents}
            />
            <DocumentListHeader
              documents={this.props.documents}
              noDocuments={noDocuments}
              onJumpToComment={this.props.onJumpToComment}
              showPdf={this.props.showPdf}
            />
            {tableView}
          </div>
        </AppSegment>
        <LastRetrievalInfo />
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    documents: getFilteredDocuments(state),
    ..._.pick(
      state.documentList,
      'docFilterCriteria',
      'viewingDocumentsOrComments',
    ),
    appeal: state.pdfViewer.loadedAppeal,
    manifestVbmsFetchedAt: state.documentList.manifestVbmsFetchedAt,
    manifestVvaFetchedAt: state.documentList.manifestVvaFetchedAt,
    queueRedirectUrl: state.documentList.queueRedirectUrl,
    queueTaskType: state.documentList.queueTaskType,
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      onReceiveAppealDetails,
      fetchAppealDetails,
      saveCavDocketNumber,
      saveVeteranDetails,
    },
    dispatch,
  )

export default connect(mapStateToProps, mapDispatchToProps)(PdfListView)

PdfListView.propTypes = {
  appeal: PropTypes.any,
  docFilterCriteria: PropTypes.any,
  documentPathBase: PropTypes.any,
  documents: PropTypes.arrayOf(PropTypes.object).isRequired,
  fetchAppealDetails: PropTypes.any,
  saveVeteranDetails: PropTypes.func,
  match: PropTypes.any,
  onJumpToComment: PropTypes.func,
  onReceiveAppealDetails: PropTypes.any,
  queueRedirectUrl: PropTypes.any,
  queueTaskType: PropTypes.any,
  saveCavDocketNumber: PropTypes.any,
  showPdf: PropTypes.any,
  sortBy: PropTypes.string,
  viewingDocumentsOrComments: PropTypes.any,
}
