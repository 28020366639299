import React from 'react'
import PropTypes from 'prop-types'
import Mark from 'mark.js'

import CommentLayer from './CommentLayer'
import {connect} from 'react-redux'
import _ from 'lodash'
import {setSearchIndexToHighlight} from './PdfSearch/PdfSearchActions'
import {setDocScrollPosition} from './PdfViewer/PdfViewerActions'
import {
  getSearchTerm,
  getCurrentMatchIndex,
  getMatchesPerPageInFile,
} from '../reader/selectors'
import {bindActionCreators} from 'redux'
import {
  PDF_PAGE_HEIGHT,
  PDF_PAGE_WIDTH,
  SEARCH_BAR_HEIGHT,
  PAGE_DIMENSION_SCALE,
  PAGE_MARGIN,
} from './constants'
import {pageNumberOfPageIndex} from './utils'
import {renderTextLayer} from 'pdfjs-dist'

import {css} from 'glamor'
import classNames from 'classnames'
import {COLORS} from '../../constants/AppConstants'

const markStyle = css({
  '& mark': {
    background: '#FFA500',
    '.highlighted': {
      background: 'blue',
    },
  },
})

export class PdfPage extends React.PureComponent {
  constructor(props) {
    super(props)

    this.isDrawing = false
    this.renderTask = null
    this.marks = []
    this.measureTimeStartMs = null
  }

  getPageContainerRef = pageContainer => (this.pageContainer = pageContainer)

  getCanvasRef = canvas => (this.canvas = canvas)

  getTextLayerRef = textLayer => (this.textLayer = textLayer)

  unmarkText = (callback = _.noop) => this.markInstance.unmark({done: callback})
  markText = (scrollToMark = false, txt = this.props.searchText) => {
    this.unmarkText(() =>
      this.markInstance.mark(txt, {
        separateWordSearch: false,
        done: () => {
          if (!this.props.matchesPerPage.length || !this.textLayer) {
            return
          }

          this.marks = this.textLayer.getElementsByTagName('mark')
          this.highlightMarkAtIndex(scrollToMark)
        },
      }),
    )
  }

  highlightMarkAtIndex = scrollToMark => {
    if (this.props.pageIndexWithMatch !== this.props.pageIndex) {
      return
    }

    const selectedMark = this.marks[this.props.relativeIndex]

    if (selectedMark) {
      selectedMark.classList.add('highlighted')

      if (scrollToMark) {
        // Mark parent elements are absolutely-positioned divs. Account for search bar and margin height.
        this.props.setDocScrollPosition(
          parseInt(selectedMark.parentElement.style.top, 10) -
            (SEARCH_BAR_HEIGHT + 10) -
            PAGE_MARGIN,
        )
      }
    } else {
      console.error(
        'selectedMark not found in DOM: ' +
          `${this.props.relativeIndex} on pg ${this.props.pageIndex} (${this.props.currentMatchIndex})`,
      )
    }
  }

  getMatchIndexOffsetFromPage = (pageIndex = this.props.pageIndex) => {
    // get sum of matches from pages below pageIndex
    return _(this.props.matchesPerPage)
      .filter(page => page.pageIndex < pageIndex)
      .map(page => page.matches)
      .sum()
  }

  onClick = () => {
    if (this.marks.length) {
      this.props.setSearchIndexToHighlight(this.getMatchIndexOffsetFromPage())
    }
  }

  // This method is the interaction between our component and PDFJS.
  // When this method resolves the returned promise it means the PDF
  // has been drawn with the most up to date scale passed in as a prop.
  // We may execute multiple draws to ensure this property.
  drawPage = page => {
    this.isDrawing = true

    const currentScale = this.props.scale
    const viewport = page.getViewport({scale: this.props.scale})

    // We need to set the width and heights of everything based on
    // the width and height of the viewport.
    this.canvas.height = viewport.height
    this.canvas.width = viewport.width

    const options = {
      canvasContext: this.canvas.getContext('2d', {alpha: false}),
      viewport,
    }

    this.renderTask = page.render(options)

    // Call PDFJS to actually draw the page.
    return this.renderTask.promise
      .then(() => {
        this.isDrawing = false

        // If the scale has changed, draw the page again at the latest scale.
        if (currentScale !== this.props.scale && page) {
          return this.drawPage(page)
        }
      })
      .catch(() => {
        // We might need to do something else here.

        this.isDrawing = false
      })
  }

  componentDidMount = () => {
    this.setUpPage()
  }

  componentWillUnmount = () => {
    this.isDrawing = false

    if (this.renderTask) {
      this.renderTask.cancel()
    }

    if (this.props.page) {
      this.props.page.cleanup()
      if (this.markInstance) {
        this.markInstance.unmark()
      }
    }
  }

  componentDidUpdate = prevProps => {
    if (this.props.isPageVisible && !prevProps.isPageVisible) {
      this.measureTimeStartMs = performance.now()
    }

    if (prevProps.scale !== this.props.scale && this.page) {
      this.drawPage(this.page)
    }

    if (this.markInstance) {
      if (this.props.searchBarHidden || !this.props.searchText) {
        this.unmarkText()
      } else {
        const searchTextChanged = this.props.searchText !== prevProps.searchText
        const currentMatchIdxChanged =
          !_.isNaN(this.props.relativeIndex) &&
          this.props.relativeIndex !== prevProps.relativeIndex
        const pageIndexChanged =
          !_.isNaN(this.props.pageIndexWithMatch) &&
          this.props.pageIndexWithMatch !== prevProps.pageIndexWithMatch

        if (this.props.matchesPerPage.length || searchTextChanged) {
          this.markText(
            currentMatchIdxChanged || searchTextChanged || pageIndexChanged,
          )
        }
      }
    }
  }

  drawText = (page, text) => {
    if (!this.textLayer) {
      return
    }

    const viewport = page.getViewport({scale: PAGE_DIMENSION_SCALE})

    this.textLayer.innerHTML = ''
    renderTextLayer({
      textContent: text,
      container: this.textLayer,
      viewport,
      textDivs: [],
    }).promise.then(() => {
      this.markInstance = new Mark(this.textLayer)

      if (this.props.searchText && !this.props.searchBarHidden) {
        this.markText()
      }
    })
  }

  getText = page => page.getTextContent()

  // Set up the page component in the Redux store. This includes the page dimensions, text,
  // and PDFJS page object.
  setUpPage = () => {
    if (
      this.props.pdfDocument &&
      !this.props.pdfDocument.loadingTask.destroyed
    ) {
      this.props.pdfDocument
        .getPage(pageNumberOfPageIndex(this.props.pageIndex))
        .then(page => {
          // console.log('inpdfpage getpage', page)
          this.page = page

          this.getText(page).then(text => {
            this.drawText(page, text)
          })

          this.drawPage(page)
        })
        .catch(() => {
          // We might need to do something else here.
        })
    }
  }

  getDivDimensions = () => {
    const innerDivDimensions = {
      innerDivWidth: _.get(
        this.props.pageDimensions,
        ['width'],
        PDF_PAGE_WIDTH,
      ),
      innerDivHeight: _.get(
        this.props.pageDimensions,
        ['height'],
        PDF_PAGE_HEIGHT,
      ),
    }

    // If we have rotated the page, we need to switch the width and height.
    if (this.props.rotation === 90 || this.props.rotation === 270) {
      return {
        outerDivWidth: this.props.scale * innerDivDimensions.innerDivHeight,
        outerDivHeight: this.props.scale * innerDivDimensions.innerDivWidth,
        ...innerDivDimensions,
      }
    }

    return {
      outerDivWidth: this.props.scale * innerDivDimensions.innerDivWidth,
      outerDivHeight: this.props.scale * innerDivDimensions.innerDivHeight,
      ...innerDivDimensions,
    }
  }

  render() {
    const pageClassNames = classNames({
      'cf-pdf-pdfjs-container': true,
      page: true,
      'cf-pdf-placing-comment': this.props.isPlacingAnnotation,
    })
    const {outerDivWidth, outerDivHeight, innerDivWidth, innerDivHeight} =
      this.getDivDimensions()

    // When you rotate a page 90 or 270 degrees there is a translation at the top equal to the difference
    // between the current width and current height. We need to undo that translation to get things to align.
    const translateX =
      (Math.sin((this.props.rotation / 180) * Math.PI) *
        (outerDivHeight - outerDivWidth)) /
      2
    const divPageStyle = {
      marginBottom: `${PAGE_MARGIN * this.props.scale}px`,
      width: `${outerDivWidth}px`,
      height: `${outerDivHeight}px`,
      verticalAlign: 'top',
      display: this.props.isFileVisible ? '' : 'none',
    }
    // Pages that are currently drawing should not be visible since they may be currently rendered
    // at the wrong scale.
    const pageContentsVisibleClass = classNames({
      'cf-pdf-page-hidden': this.props.isDrawing,
    })
    // This div is the one responsible for rotating the page. It is within the outer div which changes
    // its width and height based on whether this page has been rotated to be in a portrait or landscape view.
    const innerDivStyle = {
      transform: `rotate(${this.props.rotation}deg) translateX(${translateX}px)`,
    }

    return (
      <div
        id={
          this.props.isFileVisible
            ? `pageContainer${pageNumberOfPageIndex(this.props.pageIndex)}`
            : null
        }
        className={pageClassNames}
        style={divPageStyle}
        onClick={this.onClick}
        ref={this.getPageContainerRef}
        {...markStyle}
      >
        <div
          id={
            this.props.isFileVisible
              ? `rotationDiv${pageNumberOfPageIndex(this.props.pageIndex)}`
              : null
          }
          className={pageContentsVisibleClass}
          style={innerDivStyle}
        >
          <canvas ref={this.getCanvasRef} className="canvasWrapper" />
          <div className="cf-pdf-annotationLayer">
            <CommentLayer
              documentId={this.props.documentId}
              pageIndex={this.props.pageIndex}
              scale={this.props.scale}
              getTextLayerRef={this.getTextLayerRef}
              file={this.props.file}
              dimensions={{
                width: innerDivWidth,
                height: innerDivHeight,
              }}
              isVisible={this.props.isFileVisible}
            />
          </div>
        </div>
      </div>
    )
  }
}

PdfPage.propTypes = {
  currentMatchIndex: PropTypes.any,
  documentId: PropTypes.string,
  file: PropTypes.string,
  isDrawing: PropTypes.any,
  isFileVisible: PropTypes.bool,
  isPageVisible: PropTypes.any,
  isPlacingAnnotation: PropTypes.any,
  matchesPerPage: PropTypes.any,
  page: PropTypes.any,
  pageDimensions: PropTypes.any,
  pageIndex: PropTypes.number,
  pageIndexWithMatch: PropTypes.any,
  pdfDocument: PropTypes.object,
  relativeIndex: PropTypes.any,
  rotate: PropTypes.number,
  rotation: PropTypes.any,
  scale: PropTypes.number,
  searchBarHidden: PropTypes.any,
  searchText: PropTypes.any,
  setDocScrollPosition: PropTypes.any,
  setSearchIndexToHighlight: PropTypes.any,
}

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      setDocScrollPosition,
      setSearchIndexToHighlight,
    },
    dispatch,
  ),
})

const mapStateToProps = (state, props) => {
  return {
    pageDimensions: _.get(state.pdf.pageDimensions, [
      props.file,
      props.pageIndex,
    ]),
    isPlacingAnnotation: state.annotationLayer.isPlacingAnnotation,
    rotation: _.get(state.documents, [props.documentId, 'rotation'], 0),
    searchText: getSearchTerm(state, props),
    currentMatchIndex: getCurrentMatchIndex(state, props),
    matchesPerPage: getMatchesPerPageInFile(state, props),
    searchBarHidden: state.pdfViewer.hideSearchBar,
    windowingOverscan: state.pdfViewer.windowingOverscan,
    documentType: _.get(state.documents, [props.documentId, 'type'], 'unknown'),
    ...state.searchActionReducer,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PdfPage)
