import PropTypes from 'prop-types'
import React from 'react'
import {connect} from 'react-redux'
import {rightTriangle} from '../shared/RenderFunctions'

class LastReadIndicator extends React.PureComponent {
  render() {
    if (!this.props.shouldShow) {
      return null
    }

    return (
      <span
        id="read-indicator"
        ref={this.props.getRef}
        aria-label="Most recently read document indicator"
      >
        {rightTriangle()}
      </span>
    )
  }
}

LastReadIndicator.propTypes = {
  getRef: PropTypes.any,
  shouldShow: PropTypes.any,
}

const lastReadIndicatorMapStateToProps = (state, ownProps) => ({
  shouldShow: state.documentList.pdfList.lastReadDocId === ownProps.docId,
})

export default connect(lastReadIndicatorMapStateToProps)(LastReadIndicator)
