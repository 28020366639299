import uuid from 'uuid'
import * as Sentry from '@sentry/browser'
import moment from 'moment'
import gql from 'graphql-tag'
import AWSAppSync from '../../../clients/aws_app_sync'

import * as Constants from './actionTypes'
import {CATEGORIES} from '../analytics'
import {
  hideErrorMessage,
  showErrorMessage,
  updateFilteredIdsAndDocs,
} from '../commonActions'
import {escape} from '../utils.js'

/** Annotation Modal **/

export const openAnnotationDeleteModal = (annotationId, analyticsLabel) => ({
  type: Constants.OPEN_ANNOTATION_DELETE_MODAL,
  payload: {
    annotationId,
  },
  meta: {
    analytics: {
      category: CATEGORIES.VIEW_DOCUMENT_PAGE,
      action: 'open-annotation-delete-modal',
      label: analyticsLabel,
    },
  },
})

export const closeAnnotationDeleteModal = (includeMetrics = true) => ({
  type: Constants.CLOSE_ANNOTATION_DELETE_MODAL,
  meta: includeMetrics
    ? {
        analytics: {
          category: CATEGORIES.VIEW_DOCUMENT_PAGE,
          action: 'close-annotation-delete-modal',
        },
      }
    : null,
})

export const selectAnnotation = annotationId => ({
  type: Constants.SELECT_ANNOTATION,
  payload: {
    annotationId,
  },
  meta: {
    analytics: {
      category: CATEGORIES.VIEW_DOCUMENT_PAGE,
      action: 'select-annotation',
    },
  },
})

export const startPlacingAnnotation = interactionType => ({
  type: Constants.START_PLACING_ANNOTATION,
  meta: {
    analytics: {
      category: CATEGORIES.VIEW_DOCUMENT_PAGE,
      action: 'start-placing-annotation',
      label: interactionType,
    },
  },
})

export const stopPlacingAnnotation = interactionType => dispatch => {
  dispatch(hideErrorMessage('annotation'))
  dispatch({
    type: Constants.STOP_PLACING_ANNOTATION,
    meta: {
      analytics: {
        category: CATEGORIES.VIEW_DOCUMENT_PAGE,
        action: 'stop-placing-annotation',
        label: interactionType,
      },
    },
  })
}

export const onReceiveAnnotations = annotations => dispatch => {
  dispatch({
    type: Constants.RECEIVE_ANNOTATIONS,
    payload: {annotations},
  })
  dispatch(updateFilteredIdsAndDocs())
}

export const placeAnnotation = (pageNumber, coordinates, documentId) => ({
  type: Constants.PLACE_ANNOTATION,
  payload: {
    page: pageNumber,
    x: coordinates.xPosition,
    y: coordinates.yPosition,
    documentId,
  },
})

export const showPlaceAnnotationIcon = (pageIndex, pageCoords) => ({
  type: Constants.SHOW_PLACE_ANNOTATION_ICON,
  payload: {
    pageIndex,
    pageCoords,
  },
})

export const deleteAnnotation = (doc, annotationId) => async dispatch => {
  dispatch(hideErrorMessage('annotation'))
  dispatch(closeAnnotationDeleteModal(false))
  dispatch({
    type: Constants.REQUEST_DELETE_ANNOTATION,
    payload: {
      annotationId,
    },
    meta: {
      analytics: {
        category: CATEGORIES.VIEW_DOCUMENT_PAGE,
        action: 'request-delete-annotation',
      },
    },
  })

  const mutation = gql(`
    mutation removeAnnotation($appealId: String!, $documentId: String!, $annotationId: String!) {
      removeAnnotation(appealId: $appealId, documentId: $documentId, annotationId: $annotationId) {
        id
      }
    }
  `)

  try {
    const {data} = await AWSAppSync.mutate({
      mutation,
      variables: {
        appealId: doc.appealId,
        documentId: doc.id,
        annotationId: annotationId,
      },
    })

    dispatch(hideErrorMessage('annotation'))
    dispatch({
      type: Constants.REQUEST_DELETE_ANNOTATION_SUCCESS,
      payload: {
        id: data.removeAnnotation.id,
      },
    })
  } catch (err) {
    console.log(err)
    dispatch({
      type: Constants.REQUEST_DELETE_ANNOTATION_FAILURE,
      payload: {
        annotationId,
      },
    })
    dispatch(showErrorMessage('annotation'))
  }
}

export const requestMoveAnnotation =
  (document, annotation) => async dispatch => {
    dispatch(hideErrorMessage('annotation'))
    dispatch({
      type: Constants.REQUEST_MOVE_ANNOTATION,
      payload: {
        annotation,
      },
      meta: {
        analytics: {
          category: CATEGORIES.VIEW_DOCUMENT_PAGE,
          action: 'request-move-annotation',
        },
      },
    })

    const mutation = gql(`
    mutation editAnnotation($appealId: String!, $documentId: String!, $annotationId: String!, $annotationEditInput: AnnotationEditInput!) {
      editAnnotation(appealId: $appealId, documentId: $documentId, annotationId: $annotationId, input: $annotationEditInput) {
        id
      }
    }
  `)
    try {
      const {data} = await AWSAppSync.mutate({
        mutation,
        variables: {
          appealId: document.appealId,
          documentId: document.id,
          annotationId: annotation.id,
          annotationEditInput: {
            x: annotation.x,
            y: annotation.y,
            page: annotation.page,
          },
        },
      })

      dispatch(hideErrorMessage('annotation'))
      dispatch({
        type: Constants.REQUEST_MOVE_ANNOTATION_SUCCESS,
        payload: {
          annotationId: data.editAnnotation.id,
        },
      })
    } catch (err) {
      Sentry.captureException(err)
      console.log(err)
      dispatch(showErrorMessage('annotation'))
      dispatch({
        type: Constants.REQUEST_MOVE_ANNOTATION_FAILURE,
        payload: {
          annotationId: annotation.id,
        },
      })
    }
  }

export const startEditAnnotation = annotationId => ({
  type: Constants.START_EDIT_ANNOTATION,
  payload: {
    annotationId,
  },
  meta: {
    analytics: {
      category: CATEGORIES.VIEW_DOCUMENT_PAGE,
      action: 'start-edit-annotation',
    },
  },
})

export const cancelEditAnnotation = annotationId => ({
  type: Constants.CANCEL_EDIT_ANNOTATION,
  payload: {
    annotationId,
  },
  meta: {
    analytics: {
      category: CATEGORIES.VIEW_DOCUMENT_PAGE,
      action: 'cancel-edit-annotation',
    },
  },
})

export const updateAnnotationContent = (content, annotationId) => ({
  type: Constants.UPDATE_ANNOTATION_CONTENT,
  payload: {
    annotationId,
    content,
  },
  meta: {
    analytics: {
      category: CATEGORIES.VIEW_DOCUMENT_PAGE,
      action: 'edit-annotation-content-locally',
      debounceMs: 500,
    },
  },
})

export const updateAnnotationRelevantDate = (relevantDate, annotationId) => ({
  type: Constants.UPDATE_ANNOTATION_RELEVANT_DATE,
  payload: {
    annotationId,
    relevantDate,
  },
})

export const updateNewAnnotationContent = content => ({
  type: Constants.UPDATE_NEW_ANNOTATION_CONTENT,
  payload: {
    content,
  },
})

export const updateNewAnnotationRelevantDate = relevantDate => ({
  type: Constants.UPDATE_NEW_ANNOTATION_RELEVANT_DATE,
  payload: {
    relevantDate,
  },
})

export const requestEditAnnotation =
  (annotation, document) => async dispatch => {
    // If the user removed all text content in the annotation (or if only whitespace characters remain),
    // ask the user if they're intending to delete it.
    if (!annotation.comment.trim()) {
      dispatch(
        openAnnotationDeleteModal(
          annotation.id,
          'open-by-deleting-all-annotation-content',
        ),
      )

      return
    }

    dispatch(hideErrorMessage('annotation'))
    dispatch({
      type: Constants.REQUEST_EDIT_ANNOTATION,
      payload: {
        annotationId: annotation.id,
      },
      meta: {
        analytics: {
          category: CATEGORIES.VIEW_DOCUMENT_PAGE,
          action: 'request-edit-annotation',
        },
      },
    })

    const mutation = gql(`
    mutation editAnnotation($appealId: String!, $documentId: String!, $annotationId: String!, $annotationEditInput: AnnotationEditInput!) {
      editAnnotation(appealId: $appealId, documentId: $documentId, annotationId: $annotationId, input: $annotationEditInput) {
        page
        id
      }
    }
  `)

    try {
      const {data} = await AWSAppSync.mutate({
        mutation,
        variables: {
          annotationId: annotation.id,
          appealId: document.appealId,
          documentId: document.id,
          annotationEditInput: {
            x: annotation.x,
            y: annotation.y,
            comment: escape(annotation.comment),
            page: annotation.page,
            relevantDate: annotation.relevantDate
              ? moment(annotation.relevantDate).format('YYYY-MM-DD')
              : annotation.relevantDate,
          },
        },
      })

      dispatch(hideErrorMessage('annotation'))
      dispatch({
        type: Constants.REQUEST_EDIT_ANNOTATION_SUCCESS,
        payload: {
          annotationId: data.editAnnotation.id,
        },
      })
    } catch (err) {
      Sentry.captureException(err)
      console.log(err)
      dispatch(
        showErrorMessage(
          'annotation',
          'Cannot update the comment, please try again.',
        ),
      )
      dispatch({
        type: Constants.REQUEST_EDIT_ANNOTATION_FAILURE,
        payload: {
          annotationId: annotation.id,
        },
      })
    }
  }

export const createAnnotation = (annotation, document) => async dispatch => {
  const temporaryId = uuid.v4()

  dispatch(hideErrorMessage('annotation'))
  dispatch({
    type: Constants.REQUEST_CREATE_ANNOTATION,
    payload: {
      annotation: {
        ...annotation,
        id: temporaryId,
      },
    },
  })

  const mutation = gql(`
    mutation addAnnotation($appealId: ID!, $documentId: ID!, $annotationAddInput: AnnotationInput!) {
      addAnnotation(appealId: $appealId, documentId: $documentId, input: $annotationAddInput) {
        id
      }
    }
  `)

  try {
    const {data} = await AWSAppSync.mutate({
      mutation,
      variables: {
        appealId: document.appealId,
        documentId: annotation.documentId,
        annotationAddInput: {
          x: annotation.x,
          y: annotation.y,
          comment: escape(annotation.comment),
          page: annotation.page,
          relevantDate: annotation.relevantDate
            ? moment(annotation.relevantDate).format('YYYY-MM-DD')
            : annotation.relevantDate,
        },
      },
    })

    dispatch({
      type: Constants.REQUEST_CREATE_ANNOTATION_SUCCESS,
      payload: {
        annotation: {
          ...annotation,
          ...data.addAnnotation,
        },
        annotationTemporaryId: temporaryId,
      },
    })
  } catch (err) {
    Sentry.captureException(err)
    console.log(err)
    dispatch(
      showErrorMessage(
        'annotation',
        'Cannot create the comment, please try again.',
      ),
    )
    dispatch({
      type: Constants.REQUEST_CREATE_ANNOTATION_FAILURE,
      payload: {
        annotationTemporaryId: temporaryId,
      },
    })
  }
}
