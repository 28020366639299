import PropTypes from 'prop-types'
import React from 'react'
import {bindActionCreators} from 'redux'
import _ from 'lodash'
import * as Sentry from '@sentry/browser'
import gql from 'graphql-tag'
import Link from '../shared/Link'
import {reloadPage} from '../reader/utils'
import {onReceiveManifests} from './DocumentList/DocumentListActions'
import {
  onReceiveDocs,
  onReceiveTags,
} from '../reader/Documents/DocumentsActions'
import {onReceiveAnnotations} from './AnnotationLayer/AnnotationActions'
import {connect} from 'react-redux'
import LoadingDataDisplay from '../shared/LoadingDataDisplay'
import {LOGO_COLORS} from '../../constants/AppConstants'
import AWSAppSync from '../../clients/aws_app_sync'
import {onReceiveAppealDetails} from './PdfViewer/PdfViewerActions'

export class ReaderLoadingScreen extends React.Component {
  createLoadPromise = async () => {
    if (
      this.props.loadedAppealId &&
      this.props.loadedAppealId === this.props.appealId
    ) {
      return Promise.resolve()
    }

    const getAppealQuery = gql(`
      query getAppeal {
        appeal(id: "Appeal:${this.props.appealId}") {
					id
					docketNumber
					veteran {
						firstName
						lastName
						middleName
					}
          documents {
						id
            appealId
            description
						contentUrl
						filename
						receiptDate
						categoryProcedural
						categoryMedical
						categoryOther
						categoryIndex
						startPageNum
						endPageNum
						tags {
							id
							text
						}
						annotations {
							id
							x
							y
							comment
							page
							relevantDate
						}
					}
				}
      }
    `)
    try {
      const {data} = await AWSAppSync.query({query: getAppealQuery})
      const processedDocuments = data.appeal.documents.map(document => {
        return document
      })
      this.props.onReceiveDocs(processedDocuments, this.props.appealId)

      const documents = data.appeal.documents
      const annotations = documents
        .map(doc => {
          const docId = doc.id
          return doc.annotations.map(annotation => ({
            ...annotation,
            documentId: docId,
          }))
        })
        .reduce((a, b) => a.concat(b), [])

      const tags = documents.reduce((acc, doc) => {
        const docId = doc.id
        acc[docId] =
          _(doc.tags)
            .map(tag => ({
              ...tag,
              documentId: docId,
            }))
            .groupBy('documentId')
            .value()[docId] || []
        return acc
      }, {})

      const {
        id,
        docketNumber,
        veteran: {firstName, lastName, middleName},
      } = data.appeal

      this.props.onReceiveAppealDetails({
        id,
        docketNumber: docketNumber,
        veteran: {
          firstName,
          lastName,
          middleName,
        },
      })
      this.props.onReceiveAnnotations(annotations)
      this.props.onReceiveTags(tags)
    } catch (err) {
      Sentry.captureException(err)
      console.log(err)
      throw err
    }
  }

  render() {
    const failStatusMessageChildren = (
      <div>
        It looks like Adjudicate was unable to load this case. Please{' '}
        <Link name="backToClaimsFolder" button="link" onClick={reloadPage}>
          refresh this page
        </Link>{' '}
        and try again.
      </div>
    )

    const loadingDataDisplay = (
      <LoadingDataDisplay
        createLoadPromise={this.createLoadPromise}
        loadingComponentProps={{
          spinnerColor: LOGO_COLORS.READER.ACCENT,
          message: 'Loading claims folder in Adjudicate...',
        }}
        failStatusMessageProps={{
          title: 'Unable to load documents',
        }}
        failStatusMessageChildren={failStatusMessageChildren}
      >
        {this.props.children}
      </LoadingDataDisplay>
    )

    return loadingDataDisplay
  }
}

ReaderLoadingScreen.propTypes = {
  appealId: PropTypes.any,
  children: PropTypes.any,
  loadedAppealId: PropTypes.any,
  onReceiveAnnotations: PropTypes.any,
  onReceiveDocs: PropTypes.any,
  onReceiveTags: PropTypes.any,
  onReceiveAppealDetails: PropTypes.any,
}

const mapStateToProps = state => ({
  loadedAppealId: state.pdfViewer.loadedAppealId,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      onReceiveDocs,
      onReceiveManifests,
      onReceiveAnnotations,
      onReceiveTags,
      onReceiveAppealDetails,
    },
    dispatch,
  )

export default connect(mapStateToProps, mapDispatchToProps)(ReaderLoadingScreen)
