export const CASE_SELECT_APPEAL = 'CASE_SELECT_APPEAL'
export const CASE_SELECTION_PAGE = 'CASE_SELECTION_PAGE'
export const RECEIVE_ASSIGNMENTS = 'RECEIVE_ASSIGNMENTS'
export const SET_VIEWED_ASSIGNMENT = 'SET_VIEWED_ASSIGNMENT'
export const SHOW_NEW_CASE_MODAL = 'SHOW_NEW_CASE_MODAL'
export const CLEAR_NEW_CASE_MODAL = 'CLEAR_NEW_CASE_MODAL'
export const NEW_CASE_SUBMIT_SUCCESS = 'NEW_CASE_SUBMIT_SUCCESS'
export const NEW_CASE_SUBMIT_FAILED = 'NEW_CASE_SUBMIT_FAILED'
export const NEW_CASE_HIDE_SUCCESS = 'NEW_CASE_HIDE_SUCCESS'
export const LOADING_DATA_OR_RESULTS = 'LOADING_DATA_OR_RESULTS'
export const UPDATE_MODIFIED_APPEAL = 'UPDATE_MODIFIED_APPEAL'
export const DELETE_APPEAL = 'DELETE_APPEAL'
export const REQUEST_REMOVE_APPEAL = 'REQUEST_REMOVE_APPEAL'
export const REQUEST_REMOVE_APPEAL_SUCCESS = 'REQUEST_REMOVE_APPEAL_SUCCESS'
export const REQUEST_REMOVE_APPEAL_FAILURE = 'REQUEST_REMOVE_APPEAL_FAILURE'
export const REQUEST_REMOVE_APPEAL_REQUEST = 'REQUEST_REMOVE_APPEAL_REQUEST'
