import _ from 'lodash'
import * as Sentry from '@sentry/browser'
import * as Constants from './actionTypes'
import gql from 'graphql-tag'
import uuid from 'uuid'

import {
  hideErrorMessage,
  showErrorMessage,
  updateFilteredIdsAndDocs,
} from '../commonActions'
import {categoryFieldNameOfCategoryName} from '../utils'
import {collectAllTags, setLoadedAppealId} from '../PdfViewer/PdfViewerActions'
import {handleSetLastRead} from '../DocumentList/DocumentListActions'
import {setViewedAssignment} from '../CaseSelect/CaseSelectActions'
import {CATEGORIES} from '../analytics'
import AWSAppSync from '../../../clients/aws_app_sync'

/** Tags **/

export const newTagRequestSuccess =
  (docId, createdTags) => (dispatch, getState) => {
    dispatch({
      type: Constants.REQUEST_NEW_TAG_CREATION_SUCCESS,
      payload: {
        docId,
        createdTags,
      },
    })
    const {documents} = getState()

    dispatch(collectAllTags(documents))
  }

export const newTagRequestFailed =
  (docId, tagsThatWereAttemptedToBeCreated) => dispatch => {
    dispatch(showErrorMessage('tag'))
    dispatch({
      type: Constants.REQUEST_NEW_TAG_CREATION_FAILURE,
      payload: {
        docId,
        tagsThatWereAttemptedToBeCreated,
      },
    })
  }

export const removeTagRequestFailure = (docId, tagId) => dispatch => {
  dispatch(showErrorMessage('tag'))
  dispatch({
    type: Constants.REQUEST_REMOVE_TAG_FAILURE,
    payload: {
      docId,
      tagId,
    },
  })
}

export const removeTagRequestSuccess =
  (docId, tagId) => (dispatch, getState) => {
    dispatch(hideErrorMessage('tag'))
    dispatch({
      type: Constants.REQUEST_REMOVE_TAG_SUCCESS,
      payload: {
        docId,
        tagId,
      },
    })
    const {documents} = getState()

    dispatch(collectAllTags(documents))
  }

export const removeTag = (doc, tag, appealId) => async dispatch => {
  dispatch({
    type: Constants.REQUEST_REMOVE_TAG,
    payload: {
      docId: doc.id,
      tagId: tag.id,
    },
  })
  if (!tag.temporaryId) {
    try {
      const removeTagMutation = gql(`
        mutation removeTag($appealId: String!, $documentId: String!, $removeTagInput: TagInput!) {
          removeTag(appealId: $appealId, documentId: $documentId input: $removeTagInput) {
            id
            text
          }
        }
      `)
      await AWSAppSync.mutate({
        mutation: removeTagMutation,
        variables: {
          removeTagInput: {
            text: tag.text,
          },
          appealId: `Appeal:${appealId}`,
          documentId: doc.id,
        },
      })
      dispatch(removeTagRequestSuccess(doc.id, tag.id))
    } catch (err) {
      Sentry.captureException(err)
      dispatch(removeTagRequestFailure(doc.id, tag.id))
    }
  }
}

export const addNewTag = (doc, tags, appealId) => async dispatch => {
  const currentTags = doc.tags

  const newTags = _(tags)
    .differenceWith(
      currentTags,
      (tag, currentTag) => tag.value === currentTag.text,
    )
    .map(tag => ({
      text: tag.label,
      id: uuid.v4(),
      temporaryId: true,
    }))
    .value()

  if (_.size(newTags)) {
    dispatch(hideErrorMessage('tag'))
    dispatch({
      type: Constants.REQUEST_NEW_TAG_CREATION,
      payload: {
        newTags,
        docId: doc.id,
      },
    })
    newTags.forEach(async tag => {
      try {
        const addTagMutation = gql(`
          mutation addTag($appealId: String!, $documentId: String!, $addTagInput: TagInput!) {
            addTag(appealId: $appealId, documentId: $documentId input: $addTagInput) {
              id
              text
            }
          }
        `)

        const {data} = await AWSAppSync.mutate({
          mutation: addTagMutation,
          variables: {
            addTagInput: {
              text: tag.text,
            },
            appealId: `Appeal:${appealId}`,
            documentId: doc.id,
          },
        })

        const addedTags = [
          {
            id: data.addTag.id,
            text: data.addTag.text,
          },
        ]
        dispatch(newTagRequestSuccess(doc.id, addedTags))
      } catch (err) {
        Sentry.captureException(err)
        console.log(err)
        dispatch(newTagRequestFailed(doc.id, newTags))
      }
    })
  }
}

/** Document Description **/
export const saveDocumentDescription = (doc, description) => async dispatch => {
  const newDescription = _.isEmpty(description) ? null : description
  const mutation = gql(`
    mutation editDocument($appealId: String!, $documentId: String!, $documentEditInput: DocumentEditInput!) {
      editDocument(appealId: $appealId, documentId: $documentId, input: $documentEditInput) {
        id
        description
      }
    }
  `)
  console.log(doc)
  try {
    const {
      data: {
        editDocument: {id, description},
      },
    } = await AWSAppSync.mutate({
      mutation,
      variables: {
        appealId: doc.appealId,
        documentId: doc.id,
        documentEditInput: {
          description: newDescription,
        },
      },
    })
    dispatch({
      type: Constants.SAVE_DOCUMENT_DESCRIPTION_SUCCESS,
      payload: {
        docId: id,
        description,
      },
    })
  } catch (err) {
    Sentry.captureException(err)
    console.log(err)
    dispatch(showErrorMessage('description', err.message))
  }
}

export const changePendingDocDescription = (docId, description) => dispatch => {
  dispatch(hideErrorMessage('description'))
  dispatch({
    type: Constants.CHANGE_PENDING_DOCUMENT_DESCRIPTION,
    payload: {
      docId,
      description,
    },
  })
}

export const resetPendingDocDescription = docId => dispatch => {
  dispatch(hideErrorMessage('description'))
  dispatch({
    type: Constants.RESET_PENDING_DOCUMENT_DESCRIPTION,
    payload: {
      docId,
    },
  })
}

/** Rotate Pages **/

export const rotateDocument = docId => ({
  type: Constants.ROTATE_PDF_DOCUMENT,
  payload: {
    docId,
  },
})

/** Set current PDF **/
export const selectCurrentPdfLocally = docId => dispatch => {
  dispatch(handleSetLastRead(docId))
  dispatch({
    type: Constants.SELECT_CURRENT_VIEWER_PDF,
    payload: {
      docId,
    },
  })
}

export const closeDocumentUpdatedModal = docId => ({
  type: Constants.CLOSE_DOCUMENT_UPDATED_MODAL,
  payload: {
    docId,
  },
})

export const selectCurrentPdf = docId => dispatch => {
  // ApiUtil.patch(
  //   `/document/${docId}/mark-as-read`,
  //   {},
  //   ENDPOINT_NAMES.MARK_DOC_AS_READ
  // ).catch(err => {
  //   // eslint-disable-next-line no-console
  //   console.log('Error marking as read', docId, err);
  // });
  // dispatch(selectCurrentPdfLocally(docId));
}

export const onReceiveDocs = (documents, appealId) => dispatch => {
  dispatch({
    type: Constants.RECEIVE_DOCUMENTS,
    payload: {
      documents,
    },
  })
  dispatch(updateFilteredIdsAndDocs())
  dispatch(setViewedAssignment(appealId))
  dispatch(setLoadedAppealId(appealId))
}

export const onReceiveTags = tags => (dispatch, getState) => {
  dispatch({
    type: Constants.RECEIVE_TAGS,
    payload: {
      tags,
    },
  })
  const {documents} = getState()

  dispatch(collectAllTags(documents))
}

export const toggleDocumentCategoryFail =
  (docId, categoryKey, categoryValueToRevertTo) => dispatch => {
    dispatch(showErrorMessage('category'))
    dispatch({
      type: Constants.TOGGLE_DOCUMENT_CATEGORY_FAIL,
      payload: {
        docId,
        categoryKey,
        categoryValueToRevertTo,
      },
    })
  }

export const handleCategoryToggle =
  (doc, categoryName, toggleState) => async dispatch => {
    const categoryKey = categoryFieldNameOfCategoryName(categoryName)
    const mutation = gql(`
    mutation editDocument($appealId: String!, $documentId: String!, $documentEditInput: DocumentEditInput!) {
      editDocument(appealId: $appealId, documentId: $documentId, input: $documentEditInput) {
        id
        ${categoryKey}
      }
    }
  `)

    try {
      await AWSAppSync.mutate({
        mutation,
        variables: {
          appealId: doc.appealId,
          documentId: doc.id,
          documentEditInput: {
            [categoryKey]: toggleState,
          },
        },
      })

      dispatch(hideErrorMessage('category'))
      dispatch({
        type: Constants.TOGGLE_DOCUMENT_CATEGORY,
        payload: {
          categoryKey,
          toggleState,
          docId: doc.id,
        },
        meta: {
          analytics: {
            category: CATEGORIES.VIEW_DOCUMENT_PAGE,
            action: `${toggleState ? 'set' : 'unset'} document category`,
            label: categoryName,
          },
        },
      })
    } catch (err) {
      console.log('err' + err)
      Sentry.captureException(err)
      dispatch(toggleDocumentCategoryFail(doc.id, categoryKey, !toggleState))
    }
  }

export const handleToggleCommentOpened = docId => ({
  type: Constants.TOGGLE_COMMENT_LIST,
  payload: {
    docId,
  },
  meta: {
    analytics: {
      category: CATEGORIES.CLAIMS_FOLDER_PAGE,
      action: 'toggle-comment-list',
      label: nextState =>
        nextState.documents[parseInt(docId)].listComments ? 'open' : 'close',
    },
  },
})
