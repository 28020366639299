import PropTypes from 'prop-types'
import React, {PureComponent} from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import EditComment from './EditComment'
import CannotSaveAlert from '../reader/CannotSaveAlert'
import {plusIcon} from '../shared/RenderFunctions'
import Button from '../shared/Button'
import _ from 'lodash'
import {INTERACTION_TYPES} from './analytics'
import {
  createAnnotation,
  stopPlacingAnnotation,
  startPlacingAnnotation,
  updateNewAnnotationContent,
  updateNewAnnotationRelevantDate,
} from '../reader/AnnotationLayer/AnnotationActions'

class SideBarComments extends PureComponent {
  handleAddClick = event => {
    this.props.startPlacingAnnotation(INTERACTION_TYPES.VISIBLE_UI)
    event.stopPropagation()
  }

  stopPlacingAnnotation = () =>
    this.props.stopPlacingAnnotation('from-canceling-new-annotation')

  render() {
    let {comments} = this.props

    return (
      <div>
        <span className="cf-right-side cf-add-comment-button">
          <Button name="AddComment" onClick={this.handleAddClick}>
            <span>{plusIcon()} &nbsp; Add a comment</span>
          </Button>
        </span>
        <div id="cf-comment-wrapper" className="cf-comment-wrapper">
          {this.props.error.annotation.visible && (
            <CannotSaveAlert message={this.props.error.annotation.message} />
          )}
          <div className="cf-pdf-comment-list">
            {this.props.placedButUnsavedAnnotation && (
              <EditComment
                comment={this.props.placedButUnsavedAnnotation}
                document={this.props.document}
                id="addComment"
                defaultRelevantDate={this.props.defaultRelevantDate}
                disableOnEmpty
                onChange={this.props.updateNewAnnotationContent}
                onChangeDate={this.props.updateNewAnnotationRelevantDate}
                onCancelCommentEdit={this.stopPlacingAnnotation}
                onSaveCommentEdit={this.props.createAnnotation}
              />
            )}
            {comments}
          </div>
        </div>
      </div>
    )
  }
}

SideBarComments.propTypes = {
  comments: PropTypes.any,
  createAnnotation: PropTypes.any,
  defaultRelevantDate: PropTypes.string,
  document: PropTypes.object,
  error: PropTypes.any,
  placedButUnsavedAnnotation: PropTypes.any,
  startPlacingAnnotation: PropTypes.any,
  stopPlacingAnnotation: PropTypes.any,
  updateNewAnnotationContent: PropTypes.any,
  updateNewAnnotationRelevantDate: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    ..._.pick(
      state.annotationLayer,
      'placedButUnsavedAnnotation',
      'selectedAnnotationId',
    ),
    error: state.pdfViewer.pdfSideBarError,
  }
}

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      updateNewAnnotationContent,
      updateNewAnnotationRelevantDate,
      createAnnotation,
      stopPlacingAnnotation,
      startPlacingAnnotation,
    },
    dispatch,
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(SideBarComments)
