import PropTypes from 'prop-types'
import React from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import Link from '../shared/Link'
import {reloadPage} from '../reader/utils'
import LoadingDataDisplay from '../shared/LoadingDataDisplay'
import {LOGO_COLORS} from '../../constants/AppConstants'
import {loadAssignments} from './CaseSelect/CaseSelectActions'

export class CaseSelectLoadingScreen extends React.Component {
  createLoadPromise = async () => this.props.loadAssignments()

  render() {
    const failStatusMessageChildren = (
      <div>
        It looks like Adjudicate was unable to load the welcome page.
        <br />
        Please{' '}
        <Link name="backToClaimsFolder" button="link" onClick={reloadPage}>
          refresh this page
        </Link>{' '}
        and try again.
      </div>
    )

    return (
      <LoadingDataDisplay
        createLoadPromise={this.createLoadPromise}
        loadingComponentProps={{
          spinnerColor: LOGO_COLORS.READER.ACCENT,
          message: 'Loading cases in Reader...',
        }}
        failStatusMessageProps={{
          title: 'Unable to load the welcome page',
        }}
        failStatusMessageChildren={failStatusMessageChildren}
      >
        {this.props.children}
      </LoadingDataDisplay>
    )
  }
}

CaseSelectLoadingScreen.propTypes = {
  children: PropTypes.any,
  loadAssignments: PropTypes.any,
}

const mapStateToProps = state => ({
  assignments: state.caseSelect.assignments,
  assignmentsLoaded: state.caseSelect.assignmentsLoaded,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loadAssignments,
    },
    dispatch,
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CaseSelectLoadingScreen)
