import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import _ from 'lodash'
import ReactTooltip from 'react-tooltip'

import {SortArrowDown, SortArrowUp, DoubleArrow} from './RenderFunctions'
import {hover} from 'glamor'

/**
 * This component can be used to easily build tables.
 * The required props are:
 * - @columns {array[string]} array of objects that define the properties
 *   of the columns. Possible attributes for each column include:
 *   - @header {string|component} header cell value for the column
 *   - @align {sting} alignment of the column ("left", "right", or "center")
 *   - @valueFunction {function(rowObject)} function that takes `rowObject` as
 *     an argument and returns the value of the cell for that column.
 *   - @valueName {string} if valueFunction is not defined, cell value will use
 *     valueName to pull that attribute from the rowObject.
 *   - @footer {string} footer cell value for the column
 * - @rowObjects {array[object]} array of objects used to build the <tr/> rows
 * - @summary {string} table summary
 *
 * see StyleGuideTables.jsx for usage example.
 */
const helperClasses = {
  center: 'cf-txt-c',
  left: 'cf-txt-l',
  right: 'cf-txt-r',
}

const cellClasses = ({align, cellClass}) =>
  classnames([helperClasses[align], cellClass])

const getColumns = props => {
  return _.isFunction(props.columns)
    ? props.columns(props.rowObject)
    : props.columns
}

const HeaderRow = props => {
  const sortableHeaderStyle = hover({cursor: 'pointer'})

  return (
    <thead className={props.headerClassName}>
      <tr>
        {getColumns(props).map((column, columnNumber) => {
          const tooltipProps = {
            'data-tip': true,
            'data-for': `${columnNumber}-tooltip`,
          }
          let columnContent = (
            <span {...tooltipProps}>{column.header || ''}</span>
          )

          if (column.getSortValue) {
            const sortIndicator = props.sortAscending ? (
              <SortArrowUp />
            ) : (
              <SortArrowDown />
            )
            const notSortedIndicator = <DoubleArrow />

            columnContent = (
              <span
                {...sortableHeaderStyle}
                {...tooltipProps}
                onClick={() => props.setSortOrder(columnNumber)}
              >
                {column.header || ''}{' '}
                {props.sortColIdx === columnNumber
                  ? sortIndicator
                  : notSortedIndicator}
              </span>
            )
          }

          return (
            <th scope="col" key={columnNumber} className={cellClasses(column)}>
              {column.tooltip && (
                <ReactTooltip
                  id={`${columnNumber}-tooltip`}
                  effect="solid"
                  multiline
                >
                  {column.tooltip}
                </ReactTooltip>
              )}
              {columnContent}
            </th>
          )
        })}
      </tr>
    </thead>
  )
}

HeaderRow.propTypes = {
  headerClassName: PropTypes.any,
  setSortOrder: PropTypes.any,
  sortAscending: PropTypes.any,
  sortColIdx: PropTypes.any,
}

const getCellValue = (rowObject, rowId, column) => {
  if (column.valueFunction) {
    return column.valueFunction(rowObject, rowId)
  }
  if (column.valueName) {
    return rowObject[column.valueName]
  }

  return ''
}

const getCellSpan = (rowObject, column) => {
  if (column.span) {
    return column.span(rowObject)
  }

  return 1
}

// todo: make these functional components?
class Row extends React.PureComponent {
  render() {
    const props = this.props
    const rowId = props.footer ? 'footer' : props.rowId
    const rowClassnameCondition = classnames(
      !props.footer && props.rowClassNames(props.rowObject),
    )

    return (
      <tr id={`table-row-${rowId}`} className={rowClassnameCondition}>
        {getColumns(props)
          .filter(column => getCellSpan(props.rowObject, column) > 0)
          .map((column, columnNumber) => (
            <td
              key={columnNumber}
              className={cellClasses(column)}
              colSpan={getCellSpan(props.rowObject, column)}
            >
              {props.footer
                ? column.footer
                : getCellValue(props.rowObject, props.rowId, column)}
            </td>
          ))}
      </tr>
    )
  }
}

Row.propTypes = {
  footer: PropTypes.any,
  rowClassNames: PropTypes.any,
  rowId: PropTypes.any,
  rowObject: PropTypes.any,
}

class BodyRows extends React.PureComponent {
  render() {
    const {
      rowObjects,
      bodyClassName,
      columns,
      rowClassNames,
      tbodyRef,
      id,
      getKeyForRow,
      bodyStyling,
    } = this.props

    return (
      <tbody className={bodyClassName} ref={tbodyRef} id={id} {...bodyStyling}>
        {rowObjects.map((object, rowNumber) => {
          const key = getKeyForRow(rowNumber, object)

          return (
            <Row
              rowObject={object}
              columns={columns}
              rowClassNames={rowClassNames}
              key={key}
              rowId={key}
            />
          )
        })}
      </tbody>
    )
  }
}

BodyRows.propTypes = {
  bodyClassName: PropTypes.any,
  bodyStyling: PropTypes.any,
  columns: PropTypes.any,
  getKeyForRow: PropTypes.any,
  id: PropTypes.any,
  rowClassNames: PropTypes.any,
  rowObjects: PropTypes.any,
  tbodyRef: PropTypes.any,
}

class FooterRow extends React.PureComponent {
  render() {
    const props = this.props
    const hasFooters = _.some(props.columns, 'footer')

    return <tfoot>{hasFooters && <Row columns={props.columns} footer />}</tfoot>
  }
}

FooterRow.propTypes = {
  columns: PropTypes.any,
}

export default class Table extends React.PureComponent {
  constructor(props) {
    super(props)

    const {defaultSort} = this.props
    const state = {
      sortAscending: true,
      sortColIdx: null,
    }

    if (defaultSort) {
      Object.assign(state, defaultSort)
    }

    this.state = state
  }

  defaultRowClassNames = () => ''

  sortRowObjects = () => {
    const {rowObjects} = this.props
    const {sortColIdx, sortAscending} = this.state

    if (sortColIdx === null) {
      return rowObjects
    }

    const builtColumns = getColumns(this.props)

    return _.orderBy(
      rowObjects,
      row => builtColumns[sortColIdx].getSortValue(row),
      sortAscending ? 'asc' : 'desc',
    )
  }

  render() {
    let {
      columns,
      summary,
      headerClassName = '',
      bodyClassName = '',
      rowClassNames = this.defaultRowClassNames,
      getKeyForRow,
      slowReRendersAreOk,
      tbodyId,
      tbodyRef,
      caption,
      id,
      styling,
      bodyStyling,
    } = this.props
    const rowObjects = this.sortRowObjects()

    let keyGetter = getKeyForRow

    if (!getKeyForRow) {
      keyGetter = _.identity
      if (!slowReRendersAreOk) {
        console.warn(
          '<Table> props: one of `getKeyForRow` or `slowReRendersAreOk` props must be passed. ' +
            'To learn more about keys, see https://facebook.github.io/react/docs/lists-and-keys.html#keys',
        )
      }
    }

    return (
      <table
        id={id}
        className={`usa-table-borderless cf-table-borderless ${this.props.className}`}
        summary={summary}
        {...styling}
      >
        {caption && <caption className="usa-sr-only">{caption}</caption>}

        <HeaderRow
          columns={columns}
          headerClassName={headerClassName}
          setSortOrder={(colIdx, ascending = !this.state.sortAscending) =>
            this.setState({
              sortColIdx: colIdx,
              sortAscending: ascending,
            })
          }
          {...this.state}
        />
        <BodyRows
          id={tbodyId}
          tbodyRef={tbodyRef}
          columns={columns}
          getKeyForRow={keyGetter}
          rowObjects={rowObjects}
          bodyClassName={bodyClassName}
          rowClassNames={rowClassNames}
          bodyStyling={bodyStyling}
          {...this.state}
        />
        <FooterRow columns={columns} />
      </table>
    )
  }
}

Table.propTypes = {
  bodyClassName: PropTypes.string,
  bodyStyling: PropTypes.any,
  caption: PropTypes.string,
  className: PropTypes.string,
  columns: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.func,
  ]).isRequired,
  defaultSort: PropTypes.shape({
    sortColIdx: PropTypes.number,
    sortAscending: PropTypes.bool,
  }),
  getKeyForRow: PropTypes.any,
  headerClassName: PropTypes.string,
  id: PropTypes.string,
  keyGetter: PropTypes.func,
  rowClassNames: PropTypes.func,
  rowObjects: PropTypes.arrayOf(PropTypes.object).isRequired,
  slowReRendersAreOk: PropTypes.bool,
  sortAscending: PropTypes.bool,
  sortColIdx: PropTypes.number,
  styling: PropTypes.object,
  summary: PropTypes.string,
  tbodyId: PropTypes.string,
  tbodyRef: PropTypes.func,
}
