import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {selectCurrentPdfLocally} from '../reader/Documents/DocumentsActions'

import ViewableItemLink from '../shared/ViewableItemLink'
import Highlight from '../shared/Highlight'

class DocTypeColumn extends React.PureComponent {
  onClick = () => {
    this.props.selectCurrentPdfLocally(this.props.doc.id)
  }

  convertDescription = description => (_.isNull(description) ? '' : description)

  render = () => {
    const {doc} = this.props

    // We add a click handler to mark a document as read even if it's opened in a new tab.
    // This will get fired in the current tab, as the link is followed in a new tab. We
    // also need to add a mouseUp event since middle clicking doesn't trigger an onClick.
    // This will not work if someone right clicks and opens in a new tab.
    return (
      <div>
        <ViewableItemLink
          boldCondition={!doc.opened_by_current_user}
          onOpen={this.onClick}
          linkProps={{
            to: `${this.props.documentPathBase}/${doc.id}`,
            'aria-label':
              doc.filename +
              (doc.opened_by_current_user ? ' opened' : ' unopened'),
          }}
        >
          <Highlight>{doc.filename}</Highlight>
        </ViewableItemLink>
        {doc.description && (
          <p className="document-list-doc-description">
            <Highlight>{this.convertDescription(doc.description)}</Highlight>
          </p>
        )}
      </div>
    )
  }
}

const mapDocTypeDispatchToProps = dispatch => ({
  ...bindActionCreators(
    {
      selectCurrentPdfLocally,
    },
    dispatch,
  ),
})

DocTypeColumn.propTypes = {
  doc: PropTypes.object,
  documentPathBase: PropTypes.string,
  selectCurrentPdfLocally: PropTypes.any,
}

export default connect(null, mapDocTypeDispatchToProps)(DocTypeColumn)
