import React from 'react'
import PropTypes from 'prop-types'

import DateSelector from '../shared/DateSelector'
import SaveableTextArea from '../shared/SaveableTextArea'
import unescapeJs from 'unescape-js'

// A rounded rectangle with a text box for adding
// or editing an existing comment.
export default class EditComment extends React.Component {
  constructor(props) {
    super(props)

    this.shouldAutosave = true
  }

  isSaveDisabled = () =>
    this.props.disableOnEmpty && this.isStringEmpty(this.props.comment.comment)

  handleAutoSave = () => {
    // only autosave when a comment exists
    if (this.shouldAutosave && !this.isSaveDisabled()) {
      this.onSaveCommentEdit()
    }
  }

  keyListener = event => {
    if (
      event.altKey &&
      event.key === 'Enter' &&
      this.props.comment.comment &&
      this.props.comment.comment.trim()
    ) {
      this.onSaveCommentEdit()
      event.stopPropagation()
    }
  }

  relevantDate() {
    return this.props.comment.relevantDate || this.props.defaultRelevantDate
  }

  componentDidMount = () => {
    let commentBox = document.getElementById(this.props.id)

    commentBox.focus()

    // ensure we autosave if we ever exit
    window.addEventListener('beforeunload', this.handleAutoSave)

    const relevantDate = this.relevantDate()

    if (relevantDate) {
      this.props.onChangeDate(relevantDate, this.props.comment.uuid)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleAutoSave)
    this.handleAutoSave()
  }

  onChange = event => this.props.onChange(event, this.props.comment.uuid)

  onChangeDate = relevantDate =>
    this.props.onChangeDate(relevantDate, this.props.comment.uuid)

  onCancelCommentEdit = () => {
    this.shouldAutosave = false
    this.props.onCancelCommentEdit(this.props.comment.uuid)
  }

  onSaveCommentEdit = () => {
    this.shouldAutosave = false
    this.props.onSaveCommentEdit(this.props.comment, this.props.document)
  }

  isStringEmpty = (str = '') => !str.trim()

  render() {
    const relevantDate = this.relevantDate()

    const comment = this.props.comment.comment
      ? unescapeJs(this.props.comment.comment)
      : this.props.comment.comment

    return (
      <div>
        <DateSelector
          name="Relevant Date"
          onChange={this.onChangeDate}
          value={relevantDate}
          type="date"
          strongLabel
        />
        <SaveableTextArea
          name="Edit comment"
          hideLabel
          onKeyDown={this.keyListener}
          id={this.props.id}
          onChange={this.onChange}
          value={comment}
          onCancelClick={this.onCancelCommentEdit}
          onSaveClick={this.onSaveCommentEdit}
          disabled={this.isSaveDisabled()}
        />
      </div>
    )
  }
}

EditComment.defaultProps = {
  id: 'commentEditBox',
}

EditComment.propTypes = {
  comment: PropTypes.object.isRequired,
  defaultRelevantDate: PropTypes.string,
  disableOnEmpty: PropTypes.bool,
  document: PropTypes.object,
  id: PropTypes.string,
  onCancelCommentEdit: PropTypes.func,
  onChange: PropTypes.any,
  onChangeDate: PropTypes.any,
  onSaveCommentEdit: PropTypes.func,
}
