import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {update} from '../../util/ReducerUtil'

import FileUpload from '../shared/FileUpload'
import TextField from '../shared/TextField'
import Button from '../shared/Button'
import Modal from '../shared/Modal'
import CannotSaveAlert from '../reader/CannotSaveAlert'

import {
  showNewCaseModal,
  clearNewCaseModal,
  submitANewCase,
} from './CaseSelect/CaseSelectActions'

const BASE_STATE = {
  uploadStatus: '',
  modalDoneBtnDisabled: true,
  newCaseForm: {
    firstName: null,
    lastName: null,
    middleName: null,
    appealName: null,
    docketNumber: null,
    caseFile: null,
  },
}
class CaseSelectNewCase extends React.PureComponent {
  state = BASE_STATE

  handleInputChange = (value, target) => {
    const actualValue = target.type === 'checkbox' ? target.checked : value
    const name = target.name

    this.setState(
      update(this.state, {
        newCaseForm: {
          [name]: {
            $set: actualValue === '' ? null : actualValue,
          },
        },
      }),
    )
  }

  showNewCaseModal = () => {
    this.props.showNewCaseModal()
  }

  clearNewCaseModal = () => {
    this.setState(BASE_STATE)
    this.props.clearNewCaseModal()
  }

  uploadFile = fileupload => {
    this.setState(
      update(this.state, {
        newCaseForm: {
          caseFile: {
            $set: fileupload,
          },
        },
      }),
    )
  }

  componentDidUpdate = (prevProps, _prevState) => {
    if (!this.newCaseFormFilled(this.state.newCaseForm || {})) {
      this.setState({modalDoneBtnDisabled: false})
    }

    if (
      prevProps.caseSelect.showNewCaseModal !==
        this.props.caseSelect.showNewCaseModal &&
      this.props.caseSelect.showNewCaseModal === false
    ) {
      this.clearNewCaseModal()
    }
  }

  newCaseFormFilled = newCaseForm =>
    Object.values(newCaseForm).some(value => value === null)

  submitANewCase = () => {
    const {newCaseForm} = this.state
    this.props.submitANewCase(newCaseForm)
  }

  render() {
    const {
      handleInputChange,
      showNewCaseModal,
      clearNewCaseModal,
      uploadFile,
      submitANewCase,
      props: {
        caseSelect: {
          loading,
          newCaseSubmitFailed,
          showNewCaseModal: showNewCaseModalState,
        },
      },
      state: {
        newCaseForm: {firstName, middleName, lastName, docketNumber, caseFile},
      },
    } = this
    const requiredFields = [firstName, lastName, docketNumber, caseFile]

    return (
      <div>
        <div className="pull-right">
          <Button
            name={`delete-comment-${this.props.uuid}`}
            classNames={['cf-submit']}
            onClick={showNewCaseModal}
          >
            Add a new case
          </Button>
        </div>
        {showNewCaseModalState && (
          <Modal
            buttons={[
              {
                classNames: ['cf-modal-link', 'cf-btn-link'],
                name: 'Cancel',
                onClick: clearNewCaseModal,
              },
              {
                classNames: ['usa-button', 'usa-button-primary'],
                disabled: !requiredFields.every(Boolean) || loading,
                loading,
                name: 'Submit',
                onClick: submitANewCase,
              },
            ]}
            closeHandler={clearNewCaseModal}
            title="Add a new case file"
          >
            <div className="new-case-form">
              <form>
                {newCaseSubmitFailed && <CannotSaveAlert />}
                <fieldset>
                  <legend>Appeal</legend>
                  <TextField
                    label="CAVC Docket number *"
                    name="docketNumber"
                    value={docketNumber}
                    onChange={handleInputChange}
                  />
                </fieldset>
                <fieldset>
                  <legend>Veteran/Appellant</legend>
                  <TextField
                    label="First Name *"
                    name="firstName"
                    value={firstName}
                    onChange={handleInputChange}
                  />
                  <TextField
                    label="Middle Name"
                    name="middleName"
                    value={middleName}
                    onChange={handleInputChange}
                  />
                  <TextField
                    label="Last Name *"
                    name="lastName"
                    value={lastName}
                    onChange={handleInputChange}
                  />
                  <FileUpload
                    name="caseFile"
                    preUploadText="Select a veteran case file"
                    postUploadText="Choose a different file"
                    id="veteran_case_file"
                    fileType=".pdf"
                    value={caseFile}
                    onChange={uploadFile}
                  />
                </fieldset>
              </form>
            </div>
          </Modal>
        )}
      </div>
    )
  }
}

CaseSelectNewCase.propTypes = {
  caseSelect: PropTypes.any,
  clearNewCaseModal: PropTypes.any,
  showNewCaseModal: PropTypes.any,
  submitANewCase: PropTypes.any,
  uuid: PropTypes.any,
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      showNewCaseModal,
      clearNewCaseModal,
      submitANewCase,
    },
    dispatch,
  )

const mapStateToProps = state => ({
  caseSelect: state.caseSelect,
})

export default connect(mapStateToProps, mapDispatchToProps)(CaseSelectNewCase)
