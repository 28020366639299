import PropTypes from 'prop-types'
import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import _ from 'lodash'

import Link from '../shared/Link'
import Button from '../shared/Button'
import Modal from '../shared/Modal'
import {requestRemoveAppeal} from './CaseSelect/CaseSelectActions'
import {isAdmin} from '../../util/Authorization'

class ViewClaimsFolder extends PureComponent {
  constructor() {
    super()

    this.state = {
      deleteAppealModal: false,
    }
  }

  removeAppeal = (appealId, createdAt) => async () => {
    try {
      this.props.requestRemoveAppeal(appealId, createdAt)
      this.hideDeleteModal()
    } catch (e) {
      console.log(e)
    }
  }

  showDeleteModal = () => this.setState({deleteAppealModal: true})
  hideDeleteModal = () => this.setState({deleteAppealModal: false})

  render() {
    const {row} = this.props

    let buttonText = 'Open'
    let deleteButtonText = 'Delete'

    let buttonType = 'primary'

    if (row.viewed) {
      buttonText = 'Continue'
      buttonType = 'secondary'
    }

    if (row.deleting) {
      deleteButtonText = 'Deleting...'
    }

    const modalTitle = `Are you sure about deleting this case with docket number ${row.docketNumber}?`
    const legacyAppealId = row.id.split(':')[1]
    const {currentUser} = this.props

    return (
      <div>
        {!row.deleting && this.state.deleteAppealModal && (
          <Modal
            buttons={[
              {
                classNames: ['cf-modal-link', 'cf-btn-link'],
                name: 'Cancel',
                onClick: this.hideDeleteModal,
              },
              {
                classNames: ['usa-button', 'usa-button-primary'],
                name: 'Yes, delete this case',
                onClick: this.removeAppeal(row.id, row.createdAt),
              },
            ]}
            closeHandler={this.hideDeleteModal}
            title={modalTitle}
            onCancel={() => console.log('hello')}
          >
            ⚠️ This case and all it&apos;s associated documents, annotations
            will be permanently deleted. It cannot be undone.
          </Modal>
        )}

        {!row.deleting && (
          <Link
            name="view doc"
            button={buttonType}
            to={`/${legacyAppealId}/documents`}
          >
            {buttonText}
          </Link>
        )}
        {isAdmin(currentUser.roles) && (
          <Button
            name="delete case"
            button="secondary"
            onClick={this.showDeleteModal}
            loading={row.deleting}
            loadingText="Deleting..."
            dangerStyling={true}
            classNames={['usa-button-secondary']}
          >
            {deleteButtonText}
          </Button>
        )}
      </div>
    )
  }
}

ViewClaimsFolder.propTypes = {
  row: {
    docketNumber: PropTypes.String,
    id: PropTypes.String,
    viewed: PropTypes.bool,
  },
  currentUser: PropTypes.object,
  requestRemoveAppeal: PropTypes.func,
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      requestRemoveAppeal,
    },
    dispatch,
  )

const mapStateToProps = state => ({
  ..._.pick(state.auth, 'currentUser'),
})

export default connect(mapStateToProps, mapDispatchToProps)(ViewClaimsFolder)
